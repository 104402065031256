import Sku_Api from 'app/api/Sku_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import SkuForm from 'app/components/Forms/Sku';
import SkuIntegrationForm from 'app/components/Forms/Sku/Integration';
import SkuInventoryForm from 'app/components/Forms/Sku/Inventory';
import SkuLogisticsForm from 'app/components/Forms/Sku/Logistics';
import SkuContext from 'app/contexts/SkuContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataSku, SkuRelations } from 'app/types/data/IDataSku';
import DisplayAttributes from 'app/views/curated/attribute/components/DisplayAttributes';
import DisplayOrientation from 'app/views/curated/orientation/components/DisplayOrientation';
import DisplayUnit from 'app/views/curated/unit/components/DisplayUnit';
import React from 'react';

declare interface ISkuFormContainer {
  data?: IDataSku;
  relation?: IDataRelation<SkuRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  reloadMediaRelation?: boolean;
  setSelected: (data) => void;
  uuid?: string;
}

const SkuFormContainer = (props: ISkuFormContainer) => {
  const [data, setData] = React.useState<IDataSku | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <SkuContext.Provider value={data}>
      <FormContainer
        uuid={props?.uuid}
        mainForm={
          <SkuForm
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        tabs={
          data && [
            {
              title: 'Gestão Estoque',
              component: (
                <SkuInventoryForm data={data} onSubmit={props.onSubmit} relation={props.relation} />
              ),
            },
            {
              title: 'Gestão Logística',
              component: (
                <SkuLogisticsForm data={data} onSubmit={props.onSubmit} relation={props.relation} />
              ),
            },
            {
              title: 'Gestão Integração',
              component: (
                <SkuIntegrationForm
                  data={data}
                  onSubmit={props.onSubmit}
                  relation={props.relation}
                />
              ),
            },
            {
              title: 'Atributos',
              component: (
                <DisplayAttributes
                  relation={{ id: data.id as number, relation: 'Sku' }}
                  local="sku"
                />
              ),
            },
            {
              title: 'Unidades',
              component: <DisplayUnit relation={{ id: data.id as number, relation: 'Sku' }} />,
            },
            {
              title: 'Orientações',
              component: (
                <DisplayOrientation relation={{ id: data.id as number, relation: 'Sku' }} />
              ),
            },
            {
              title: 'Mídia',
              component: (
                <GridAttachments
                  hasCardView
                  relationData={data}
                  apiModel={Sku_Api}
                  onClickAttach={props.onClickAttach}
                  reloadMediaRelation={props.reloadMediaRelation}
                />
              ),
            },
          ]
        }
      />
    </SkuContext.Provider>
  );
};

export default SkuFormContainer;

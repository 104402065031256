import { CircularProgress, Paper, Stack, styled, Typography as TypographyMui } from '@mui/material';
import { IDataVirtualShowcase } from 'app/types/data/IDataVirtualShowcase';
import React, { HTMLAttributes } from 'react';
//import ViewShowcaseProduct from './ViewShowcaseProduct';
import { IPaginationRequest } from 'app/types/data/IPagination';

//----------INTERFACES----------//
interface VirtualShowcaseProps {
  data: IDataVirtualShowcase[];
  onSelected?: (value: IDataVirtualShowcase) => void;
  loadingData: boolean;
  selected?: IDataVirtualShowcase;
  pagination?: IPaginationRequest;
}

interface CardProps extends HTMLAttributes<HTMLUListElement> {
  isSelected?: boolean;
  src?: string | null;
}

interface CardListProps {
  length: number;
}
//-------------------------------//

//----------STYLES COMPONENTS----------//
const CardList = styled('ul')<CardListProps>(({ theme, length }) => ({
  //width: '100%',
  padding: 0,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  marginBottom: '2rem',
  gap: '12px',
}));

const Card = styled('li')<CardProps>(({ theme }) => ({
  listStyle: 'none',
  '& .MuiPaper-root': {
    backgroundColor: '#fff',
    color: '#838383',
  },
  '& .MuiPaper-root:hover': {
    backgroundColor: '#a5c7a7',
    color: '#fff',
  },
  borderRadius: '5px',
  width: '250px',
  cursor: 'pointer',
}));

const Typography = styled(TypographyMui)<CardProps>(({ theme, isSelected }) => ({
  color: '#838383',
  width: 'max-content',
  cursor: 'default',
}));

const CardTitle = styled('div')(({ theme }) => ({
  fontWeight: '500',
  cursor: 'default',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

const RelativeDiv = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const CardImage = styled('div')<CardProps>(({ theme, src }) => ({
  backgroundImage: `url(${src})`,
  backgroundColor: theme.palette.background.paper,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  objectFit: 'cover',
  height: '250px',
  aspectRatio: '4/3',
  marginBottom: '8px',
}));
//--------------------------------------//

const VirtualShowcase: React.FC<VirtualShowcaseProps> = ({ data, loadingData, onSelected }) => {
  // Card list
  const renderedData = data?.map((item) => (
    <div key={item.id}>
      <Card onClick={() => (onSelected ? onSelected(item) : undefined)}>
        <Paper elevation={5} sx={{ padding: '8px' }}>
          <CardImage
            src={
              item?.skus?.[0]?.medias[0]?.original_url ||
              '/assets/images/placeholder/placeholder_umami.jpg'
            }
          />
          <CardTitle key={item.id}>{item.nm_produto}</CardTitle>
        </Paper>
      </Card>
    </div>
  ));

  return (
    <RelativeDiv>
      {loadingData ? (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <CircularProgress />
        </Stack>
      ) : (
        <CardList length={renderedData.length}>{renderedData}</CardList>
      )}
      {!loadingData && !Boolean(data.length) && (
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img src="/assets/images/no-data.jpg" alt="" width="35%" />
          <Typography variant="h5" mb={2}>
            Dados não encontrados.
          </Typography>
        </Stack>
      )}
    </RelativeDiv>
  );
};

export default VirtualShowcase;

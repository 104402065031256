import { Grid, Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MediaView from 'app/components/Displays/Media/MediaView';
import { IDataAttachment } from 'app/types/data/IDataAttachment';
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const useStyles = makeStyles((theme) => ({
  navigation: {
    width: 100,
    height: 100,
    border: 'none',
    borderRadius: 0,
    backgroundColor: '#FFF',
    marginBottom: 8,
  },
  image: {
    objectFit: 'contain',
  },
  media: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  navigationContainer: {
    position: 'relative',
    display: 'inline-block',
    '& img': {
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));

interface IProductCarousel {
  media: IDataAttachment[];
}

export interface IDotNavigation {
  index: number;
  media: IDataAttachment;
}

const DotNavigations = (props: IDotNavigation) => {
  const { index, media } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.navigationContainer}>
      {media.mime_type.startsWith('video/') && (
        <img
          src="/assets/images/buttons/play-button-yellow-32.png"
          alt="Play"
          style={{
            pointerEvents: 'none', // Torna a imagem não clicável
          }}
        />
      )}
      <Dot
        key={index}
        style={{
          backgroundImage: `url(${media.thumb || media.original_url})`,
          backgroundSize: 'cover',
          marginRight: 4,
        }}
        className={classes.navigation}
        slide={index}
      />
    </div>
  );
};

const ProductCarousel = (props: IProductCarousel) => {
  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={props.media.length}
      step={1}
      naturalSlideWidth={100}
      naturalSlideHeight={100}
      interval={7000}
      hasMasterSpinner
    >
      <Grid container spacing={1}>
        <Hidden xlDown>
          <Grid item xs={2}>
            {props.media.map((image, index) => {
              return <DotNavigations index={index} media={image} key={index} />;
            })}
          </Grid>
        </Hidden>
        <Grid item sm={10} xs={12}>
          <Slider>
            {props.media.map((image, index) => {
              return (
                <Slide key={index} index={index}>
                  <MediaView key={index} media={image} />
                </Slide>
              );
            })}
          </Slider>
        </Grid>
        <Hidden xlUp>
          <Grid item xs={12}>
            {props.media.map((image, index) => {
              return <DotNavigations index={index} media={image} key={index} />;
            })}
          </Grid>
        </Hidden>
      </Grid>
    </CarouselProvider>
  );
};

export default ProductCarousel;

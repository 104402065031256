import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import Attributes_Api from 'app/api/Attributes_Api';
import AlertMessage from 'app/components/Alert';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import CategorieContext from 'app/contexts/CategorieContext';
import ProjectContext from 'app/contexts/ProjectContext';
import SkuContext from 'app/contexts/SkuContext';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import {
  IAttributePagination,
  IDataAttribute,
  IDataAttributeErrors,
} from 'app/types/data/IDataAttribute';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AttributeSchema } from '../@Schemas/AttributeSchema';
import { IFormAttribute } from '../@Types/IFormAttribute';
//import { useToast } from 'app/hooks/useToast';
import SelectAttributes from 'app/components/Inputs/Selects/SelectAttributes/SelectAttributes';
import Toast from 'app/components/Toast';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const relativeOptions = [
  { value: 'sku', label: 'SKU' },
  { value: 'produto', label: 'Produto' },
];

const initialValues: IDataAttribute = {
  id: null,
  nm_atributo: '',
  alias: '',
  parent_id: null,
  nivel: '',
  classificacao: '',
  parent: null,
  select_option: { value: '', label: '' },
  label: { value: 1, label: 'Obrigatório' },
  active: false,
  display: false,
  filter: false,
  relative_to: relativeOptions[0],
};

const labelOptions = [
  { value: 1, label: 'Obrigatório' },
  { value: 2, label: 'Essencial' },
  { value: 3, label: 'Complementar' },
];

const UPDATE_MSG = getMessage('Atributo', 'update');
const CREATE_MSG = getMessage('Atributo', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialCombo: ISelectOption = {
  value: '',
  label: '',
};

const AttributeForm = (props: IFormAttribute) => {
  const { resetFormList } = useUtilsContext();
  const projectCtxt = React.useContext(ProjectContext);
  const productCtxt = React.useContext(CatalogProductContext);
  const categorieCtxt = React.useContext(CategorieContext);
  const skuCtxt = React.useContext(SkuContext);

  const [data, setData] = React.useState<IDataAttribute>(props.data || initialValues);
  const [attributeSelected, setAttributeSelected] = React.useState<IDataAttribute | undefined>(
    undefined
  );
  const [comboData, setComboData] = React.useState<ISelectOption<IDataAttribute>[]>([]);
  //const [comboOption, setComboOption] = React.useState<ISelectOption<IDataAttribute> | null>(null);
  const [comboLoading, setComboLoading] = React.useState<boolean>(false);

  /* const [comboUmamiOption, setComboUmamiOption] =
    React.useState<ISelectOption<IDataAttribute> | null>(null); */

  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  const defaultRelativeTo: ISelectOption = data.id
    ? data.relative_to
    : props.local === 'produto'
    ? relativeOptions[1]
    : initialValues.relative_to;

  useEffect(() => {
    setData((props.data as IDataAttribute) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.attributesForm]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    if (projectCtxt) {
      setData({ ...data, projeto_id: projectCtxt.id as number, projeto: projectCtxt });
      setValue('projeto_id', projectCtxt.id as number);
      setValue('projeto', projectCtxt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCtxt]);

  async function load() {
    setComboLoading(true);
    try {
      var items: IAttributePagination;
      const combo = (comboData): ISelectOption<IDataAttribute>[] => {
        return comboData.data.map((item) => ({
          value: `${item.id}`,
          label: item.nm_atributo,
          nivel: item.nivel,
          data: item,
        }));
      };

      const isNull: string[] = [];
      isNull.push('parent_id');
      if (!projectCtxt?.id) {
        isNull.push('projeto_id');
      }

      items = await Attributes_Api.list(
        {
          q: search,
          page: 1,
          per_page: 150,
          projeto_id:
            /* (productCtxt?.projeto_id as number) || */ (projectCtxt?.id as number) || undefined,
          produto_id: skuCtxt ? (skuCtxt.produto_id as number) : undefined,
          is_null: isNull,
        }
        //props.relation
      );

      setComboData(combo(items));
      //setProjectBasedAttributes(combo(projectBasedAttributesData));
    } catch (error: IDataAttributeErrors | unknown) {
      if (error) {
        const err = error as IDataAttributeErrors;
        // eslint-disable-next-line eqeqeq
        if (err.errors.status != 403)
          err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
    getValues,
  } = useForm<IDataAttribute>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(AttributeSchema),
  });

  useEffect(() => {
    setDataValue(data, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(dataForm: IDataAttribute) {
    setLoading(true);
    try {
      var newData: IDataAttribute;

      dataForm.projeto_id = projectCtxt ? (projectCtxt.id as number) : undefined;
      dataForm.parent_id = categorieCtxt ? null : dataForm.parent_id;
      if (dataForm.id) {
        newData = await Attributes_Api.update(dataForm, props.relation);
      } else {
        newData = await Attributes_Api.create(dataForm, props.relation);
        if (props.onSubmit) props.onSubmit(newData);
      }

      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });

      setData(newData);
      setDataValue(newData, setValue);

      //load();
    } catch (error: IDataAttributeErrors | unknown) {
      if (error) {
        const err = error as IDataAttributeErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  /* function getRelativetoOption(relative_to: string) {
    switch (relative_to) {
      case 'produto':
        return { value: 'produto', label: 'Produto' };
      default:
        return { value: 'sku', label: 'SKU' };
    }
  } */

  return (
    <>
      <FormBox id="formik-Attribute" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>

          {(projectCtxt || productCtxt || skuCtxt || categorieCtxt) && (
            <Grid item xs={12} marginBottom={3}>
              <SelectAttributes
                id="atributo_id"
                label="Atributos"
                value={attributeSelected}
                onChange={(attribute) => {
                  if (attribute) {
                    const attr: IDataAttribute =
                      projectCtxt && !productCtxt && !skuCtxt && !categorieCtxt
                        ? {
                            ...attribute,
                            id: null,
                            atributo_id: attribute.id as number,
                            parent: null,
                            parent_id: null,
                            alias: undefined,
                          }
                        : attribute;
                    setData(attr);
                    setAttributeSelected(attr);
                    setDataValue(attr, setValue);
                  } else {
                    setData(initialValues);
                    setAttributeSelected(undefined);
                    setDataValue(initialValues, setValue);
                  }
                }}
                //onChangeExtraOptions={(options) => setExtraOptions(options)}
              />
            </Grid>
          )}

          {!categorieCtxt && (
            <Grid item xs={12} md={6}>
              {/* Atributo Pai */}
              <Controller
                name="parent_id"
                control={control}
                defaultValue={data?.parent_id}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    loading={comboLoading}
                    noOptionsText="Nenhum atributo"
                    loadingText="Carregando atributos..."
                    options={comboData}
                    defaultValue={initialCombo}
                    onClose={() => setSearch('')}
                    value={data.parent?.select_option || { value: '', label: '' }}
                    isOptionEqualToValue={(option, value) => value && option.value === value.value}
                    getOptionDisabled={(option) => option?.data.id === data?.id}
                    onChange={(event, option: any) => {
                      if (option) {
                        setData({
                          ...data,
                          parent_id: option.value,
                          parent: {
                            id: option.value,
                            nm_atributo: option.label,
                            alias: option.data.alias,
                            select_option: option,
                            parent_id: option.data.parent_id,
                          },
                        });
                        onChange(option.value);
                      } else {
                        setData({ ...data, parent_id: null, parent: null });
                        onChange(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TimedTextField
                        {...params}
                        id="parent_id"
                        label="Atributo Pai"
                        type="text"
                        size="small"
                        onChange={(e) => setSearch(e.target.value)}
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {errors.parent_id?.message}
                          </Typography>
                        }
                      />
                    )}
                    renderOption={(props, option) => {
                      const ident = (option.nivel?.toString().match(/\./g) || []).length;

                      return (
                        <li style={{ padding: 8, paddingLeft: 16 }} {...props}>
                          <span style={{ paddingLeft: 8 * ident }}>
                            <span style={{ fontWeight: option.data?.parent_id ? 300 : 700 }}>
                              {option.data?.nm_atributo}
                            </span>{' '}
                            <Typography variant="caption" color="gray">{`(ID: ${
                              option.data.id
                            } / Alias: ${option.data.alias} ${
                              Boolean(option.data?.relative_to)
                                ? '/ Class.: ' + option.data?.relative_to.label
                                : ''
                            })`}</Typography>
                          </span>
                        </li>
                      );
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={2}>
            <Controller
              name="id"
              control={control}
              defaultValue={data?.id}
              render={({ field: { onChange } }) => (
                <TextField
                  id="form-attribute-id"
                  label="ID"
                  type="text"
                  disabled={true}
                  value={data?.id}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: Boolean(data?.id) }}
                  onChange={(e) => {
                    setData({ ...data, id: parseInt(e.target.value) });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Controller
              name="alias"
              control={control}
              defaultValue={data?.alias}
              render={({ field: { onChange } }) => (
                <TextField
                  id="alias"
                  label="Alias Interno"
                  type="text"
                  value={data.alias}
                  autoComplete={'off'}
                  fullWidth
                  multiline
                  inputProps={{
                    maxLength: 255,
                  }}
                  variant="outlined"
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.alias?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, alias: e.target.value });
                    onChange(e);
                  }}
                  disabled={Boolean(data.id)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nm_atributo"
              control={control}
              defaultValue={data?.nm_atributo}
              render={({ field: { onChange } }) => (
                <TextField
                  id="nm_atributo"
                  label="Nome*"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  value={data?.nm_atributo}
                  autoComplete={'off'}
                  fullWidth
                  multiline
                  inputProps={{
                    maxLength: Boolean(data.parent_id) ? 10000 : 255,
                  }}
                  variant="outlined"
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nm_atributo?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nm_atributo: e.target.value });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
          {!Boolean(data.parent_id) && (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name="label"
                  control={control}
                  defaultValue={data.label}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      options={[
                        { value: 1, label: 'Obrigatório' },
                        { value: 2, label: 'Essencial' },
                        { value: 3, label: 'Complementar' },
                      ]}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box
                            component="div"
                            sx={{
                              width: 14,
                              height: 14,
                              flexShrink: 0,
                              borderRadius: '3px',
                              mr: 1,
                              mt: '2px',
                            }}
                            style={{
                              backgroundColor: option.value
                                ? { 1: '#e81e36', 2: '#d6cb00', 3: '#21ad1a' }[option.value]
                                : '#fff',
                            }}
                          />
                          <Box
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            {option.label}
                          </Box>
                        </li>
                      )}
                      defaultValue={data.label || { value: 1, label: 'Obrigatório' }}
                      value={labelOptions.filter((opt) => opt.value === data.label)[0]}
                      onChange={(event, option: any, reason) => {
                        setData({ ...data, label: option });
                        onChange(option);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="label"
                          label="Etiqueta"
                          type="text"
                          size="small"
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.label?.message}
                            </Typography>
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="relative_to"
                  control={control}
                  defaultValue={defaultRelativeTo}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      options={relativeOptions}
                      getOptionLabel={(option: ISelectOption) => option.label ?? ''}
                      isOptionEqualToValue={(option, value) => option.value === value}
                      /* defaultValue={
                    relativeOptions.filter((opt) => opt.value === data.relative_to)[0] ||
                    relativeOptions[0]
                  } */
                      value={defaultRelativeTo}
                      onChange={(event, option: any, reason) => {
                        setData({ ...data, relative_to: option ? option : null });
                        onChange(option);
                      }}
                      disabled={Boolean(productCtxt && !categorieCtxt)}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="relative_to"
                          label="Classificação"
                          type="text"
                          size="small"
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.relative_to?.message}
                            </Typography>
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="active"
                  control={control}
                  defaultValue={data.active}
                  render={({ field: { onChange } }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(data.active)}
                            onChange={(e) => {
                              setData({ ...data, active: !getValues('active') });
                              onChange(e.target.checked);
                            }}
                          />
                        }
                        label="Ativo"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="display"
                  control={control}
                  defaultValue={data.display}
                  render={({ field: { onChange } }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(data.display)}
                            onChange={(e) => {
                              setData({ ...data, display: !getValues('display') });
                              onChange(e.target.checked);
                            }}
                          />
                        }
                        label="Exibe"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="filter"
                  control={control}
                  defaultValue={data.filter}
                  render={({ field: { onChange } }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(data.filter)}
                            onChange={(e) => {
                              setData({ ...data, filter: !getValues('filter') });
                              onChange(e.target.checked);
                            }}
                          />
                        }
                        label="Filtro"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
            Salvar
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default AttributeForm;

import axios from 'axios';

const hostname = 'localhost';
const apiURL = process.env.REACT_APP_API_URL || `http://${hostname}/api`;

const Api = axios.create({
  baseURL: apiURL,
});

Api.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

Api.interceptors.request.use(
  (config) => {
    const accessToken = window.sessionStorage.getItem('accessToken');
    config.headers['Authorization'] = 'bearer ' + accessToken;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default Api;

import { InsertDriveFile } from '@mui/icons-material';
import { Grid, styled } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import { themeColors } from 'app/components/MatxTheme/themeColors';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { TViewOptions } from 'app/types/data/IData';
import { IDataAttachment, IDataAttachmentErrors } from 'app/types/data/IDataAttachment';
import { IDataUser } from 'app/types/data/IDataUser';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import React, { Fragment } from 'react';
import DisplayMediaCards from '../../Media/Cards';
var humanFormat = require('human-format');

const AvatarName = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& span': {
    marginLeft: theme.spacing(2),
  },
  '& img': {
    height: 50,
    borderRadius: 4,
  },
}));

const hiddenColumns = {
  create_user: false,
  create_user_id: false,
  update_user: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'original_url',
    headerName: 'Preview',
    width: 80,
    editable: false,
    align: 'center',
    renderCell: (params: GridRenderCellParams<Date>) => {
      if (params.row.mime_type && params.row.mime_type.startsWith('image')) {
        return (
          <AvatarName>
            <img alt={params.row.name} src={params.row.original_url} />
          </AvatarName>
        );
      } else {
        return <InsertDriveFile />;
      }
    },
  },
  {
    field: 'file_name',
    headerName: 'Arquivo',
    flex: 1,
    renderCell: (params: GridRenderCellParams<string, IDataAttachment>) => (
      <a
        style={{ color: themeColors.blue.palette.primary.main }}
        href={params.row.original_url}
        target="_blank"
        rel="noreferrer"
      >
        {params.value}
      </a>
    ),
    valueFormatter: (param: GridValueFormatterParams) =>
      param?.api?.getRow(param.id)?.original_url || param.value,
  },
  {
    field: 'mime_type',
    headerName: 'Tipo',
    flex: 0.5,
  },
  {
    field: 'size',
    headerName: 'Tamanho',
    flex: 0.35,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      humanFormat(parseInt(params.value)),
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user_id',
    headerName: 'Atualizador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

const UPDATE_MSG = getMessage('Anexo', 'update');
const DELETE_MSG = getMessage('Anexo', 'delete');
const DOWNLOADED_MESSAGE_SUCCESS = 'Imagem baixada com sucesso!';
const DOWNLOADED_MESSAGE_ERROR = 'Não foi possível baixar a imagem, tente novamente mais tarde';
const ORDER_MESSAGE_ERROR = 'Não foi possível atualizar, tente novamente mais tarde.';

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
};

declare interface IGridAttachments {
  relationData?: any;
  apiModel: any;
  hasCardView?: boolean;
  onClickAttach?: () => void;
  onImportClick?: () => void;
  reloadMediaRelation?: boolean;
}

const GridAttachments = (props: IGridAttachments) => {
  const [data, setData] = React.useState<IDataAttachment[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataAttachment>(data[0]);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDownloadingImage, setIsDownloadingImage] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [typeView, onChangeTypeView] = React.useState<TViewOptions>('grid');

  useTrailHistory({
    selected,
    selectedName: selected?.name,
    displayName: 'Anexos',
    toggleView: () => {},
  });

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relationData, search, pageSize, page, params, props.reloadMediaRelation]);

  React.useEffect(() => {
    if (!selected) {
      setSelected(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeView]);

  async function load() {
    setLoading(true);
    try {
      const resp = await props.apiModel.listAttachments(
        {
          q: search,
          page: typeView === 'card' ? 1 : page + 1,
          per_page: typeView === 'card' ? 999 : pageSize,
          ...params,
        },
        props.relationData
      );
      setData(resp.data);
      setSelected(resp.data[0]);
      setTotal(resp.pagination.total);
    } catch (error: IDataAttachmentErrors | unknown) {
      if (error) {
        const err = error as IDataAttachmentErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  function setDeleteModal(image?: IDataAttachment) {
    const imgDelete = image || selected;
    setAlertModal({
      open: true,
      title: 'Remover Arquivo',
      message: (
        <span>
          Tem certeza que deseja remover arquivo{' '}
          <strong>
            {imgDelete?.id} -{' '}
            {imgDelete && imgDelete.file_name.length > 30
              ? `${imgDelete?.file_name.substring(0, 30)}...`
              : imgDelete?.file_name}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDeleteAttach(image),
    });
  }

  async function handleDeleteAttach(image?: IDataAttachment) {
    const imgDelete = image || selected;

    try {
      setLoading(true);
      await props.apiModel.detach(imgDelete);
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataAttachmentErrors | unknown) {
      if (error) {
        const err = error as IDataAttachmentErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`,
            message: err.errors.message,
            onClose: () => setAlertModal({ ...alertModal, open: false }),
            onConfirm: () => setAlertModal({ ...alertModal, open: false }),
          });
      }
    } finally {
      setLoading(false);
      load();
    }
  }

  async function handleDownloadMedia() {
    const as_copy = selected?.custom_properties?.as_copy;
    const link = selected?.custom_properties?.url[0];
    if (as_copy === false && as_copy !== undefined) {
      try {
        setIsDownloadingImage(true);
        await props.apiModel.attach(props.relationData, [], [link], 1);
        setToast({ open: true, message: DOWNLOADED_MESSAGE_SUCCESS, severity: 'success' });
        load();
        setIsDownloadingImage(false);
      } catch (error) {
        setIsDownloadingImage(false);
        setToast({ open: true, message: DOWNLOADED_MESSAGE_ERROR, severity: 'error' });
      }
    }
  }

  function onFilter(filters: IParamsQs) {
    setParams(filters);
  }

  function handleChangeView() {
    onChangeTypeView(typeView === 'grid' ? 'card' : 'grid');
  }

  async function handleDragEnd(image: IDataAttachment, origin: number, destination: number) {
    try {
      await props.apiModel.orderAttachment(image, props.relationData, origin, destination);
    } catch (error) {
      setToast({ open: true, message: ORDER_MESSAGE_ERROR, severity: 'error' });
    } finally {
      load();
    }
  }

  function handleMediaDelete(image: IDataAttachment) {
    setSelected(image);
    setDeleteModal(image);
  }

  const ToolbarComponent = (toolbarProps) => (
    <Toolbar
      options={{
        total: {
          value: total,
        },
        view: {
          state: typeView,
        },
        data: data,
        isLoadingGrid: loading,
        isDownloadingImage,
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        selectedRow: selected,
      }}
      onClickDownloadMedia={() => handleDownloadMedia()}
      onClickAttach={props.onClickAttach ? props.onClickAttach : undefined}
      onImportClick={props.onImportClick ? props.onImportClick : undefined}
      onDeleteClick={() => setDeleteModal()}
      onClickRefresh={() => load()}
      searchValue={search}
      onSearchChange={(value) => setSearch(value)}
      onClickView={props.hasCardView ? () => handleChangeView() : undefined}
      {...toolbarProps}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        {typeView === 'grid' && (
          <Grid container display="flex" flexDirection="column" spacing={3}>
            <TableMUI
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns,
                },
              }}
              density="standard"
              columns={columns}
              rows={data}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              autoHeight
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              customToolbar={() => (
                <ToolbarComponent hasExportButton isGridRef fileName="UMAMI _ ANEXOS" />
              )}
            />
          </Grid>
        )}
        {typeView === 'card' && (
          <Grid container display="flex" flexDirection="column" spacing={3}>
            <ToolbarComponent hasExportButton fileName="UMAMI _ ANEXOS" />
            <DisplayMediaCards
              medias={data}
              loading={loading}
              onDragEnd={handleDragEnd}
              onDelete={handleMediaDelete}
            />
          </Grid>
        )}

        <AlertModal
          open={alertModal?.open}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />

        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default GridAttachments;

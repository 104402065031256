const Endpoints = {
  GroupUser: {
    main: '/role',
  },
  Permission: {
    main: '/permission',
  },
  User: {
    main: '/user',
    login: '/auth',
    logout: '/logout',
    me: '/me',
    forgot: '/password/forgot',
    reset: '/password/reset',
    profile: '/profile',
  },
  Profile: {
    main: '/profile',
    avatar: `/profile/avatar`,
  },
  Orientation: {
    main: '/orientacao',
  },
  Project: {
    main: '/projeto',
  },
  Integrator: {
    main: '/integrador',
  },
  System: {
    main: '/sistema',
  },
  Category: {
    main: '/categoria',
    combo: '/categoria/combo',
  },
  Marketplace: {
    main: '/marketplace',
  },
  Brand: {
    main: '/marca',
  },
  Unit: {
    main: '/unidade',
  },
  Attribute: {
    main: '/atributo',
    combo: '/atributo/combo',
  },
  Product: {
    main: '/produto',
  },
  Product3p: {
    main: '/produto3p',
  },
  ProductCategory3p: {
    main: '/mapeamento/categorias',
  },
  Store: {
    main: '/loja',
  },
  Store3p: {
    main: '/loja3p',
  },
  MapCategories3p: {
    main: '/mapeamento_categoria_3p',
  },
  Address: {
    main: '/endereco',
  },
  Contact: {
    main: '/contato',
  },
  Sellers: {
    main: '/sellercenter',
  },
  SellerChannels: {
    main: '/canal_vendas',
  },
  Shopping: {
    main: '/shopping',
  },
  Partner: {
    main: '/parceiro',
  },
  PartnerProduct: {
    main: '/parceiro_produto',
  },
  PartnerCategory: {
    main: '/parceiro_categoria',
  },
  PartnerAttribute: {
    main: '/parceiro_atributo',
  },
  ServiceType: {
    main: 'tipo_servico',
  },
  StoreStatus: {
    main: 'status_loja',
  },
  ImportantInfo: {
    main: 'informacao',
  },
  Sku: {
    main: 'sku',
  },
  SegmentationModel: {
    main: 'modelo_segmentacao',
  },
  Segments: {
    main: 'segmento',
  },
  SegmentationTypes: {
    main: 'tipo_segmentacao',
  },
  Segmentation: {
    main: 'segmentacao',
  },
  VirtualShowcase: {
    main: 'vitrine',
  },
  ReasonType: {
    main: 'tipo_motivo',
  },
  Workflow: {
    main: 'workflow',
  },
  WorkflowPhases: {
    main: 'etapa',
  },
  Reasons: {
    main: 'motivo',
  },
  Activity: {
    main: 'atividade',
  },
  ActivitySuccessors: {
    main: 'predecessor',
  },
  Comment: {
    main: 'comentario',
  },
};

export default Endpoints;

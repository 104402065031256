import { Avatar, Grid, styled } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import Sku_Api from 'app/api/Sku_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import { themeColors } from 'app/components/MatxTheme/themeColors';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataAttachment, IDataAttachmentErrors } from 'app/types/data/IDataAttachment';
import { IDataSku, IDataSkuErrors } from 'app/types/data/IDataSku';
import { IDataUser } from 'app/types/data/IDataUser';
import filterObjectColumns from 'app/utils/filterObjectColumns';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import React, { Fragment, SetStateAction } from 'react';
import { CSVLink } from 'react-csv';
var humanFormat = require('human-format');

const ImageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& span': {
    marginLeft: theme.spacing(2),
  },
}));

const hiddenColumns = {
  create_user: false,
  create_user_id: false,
  update_user: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'file_name',
    headerName: 'Arquivo',
    flex: 1,
    filterable: false,
    renderCell: (params: GridRenderCellParams<string, IDataAttachment>) => (
      <a
        style={{ color: themeColors.blue.palette.primary.main }}
        href={params.row.original_url}
        target="_blank"
        rel="noreferrer"
      >
        <ImageContainer>
          <Avatar alt={params.value} src={params.row.original_url} sizes="150" />
          <span>{params.value}</span>
        </ImageContainer>
      </a>
    ),
    valueFormatter: (params: GridValueFormatterParams) =>
      params?.api?.getRow(params.id)?.original_url || params.value,
  },
  {
    field: 'model',
    headerName: 'SKU/Nome',
    flex: 1,
    renderCell: (params: GridRenderCellParams<IDataSku, any>) => (
      <span>
        {params.value?.sku} | {params.value?.name}
      </span>
    ),
    valueFormatter: (params: GridValueFormatterParams) => params.value?.name,
  },
  {
    field: 'mime_type',
    headerName: 'Tipo',
    flex: 0.5,
  },
  {
    field: 'size',
    headerName: 'Tamanho',
    flex: 0.35,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      humanFormat(parseInt(params.value)),
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

const header = columns.map((item) => item.headerName);

const UPDATE_MSG = getMessage('Anexo', 'update');
const DELETE_MSG = getMessage('Anexo', 'delete');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
};

declare interface IGridAttachments {
  onClickAttach?: () => void;
  onImportClick?: () => void;
}

const DisplayProductImages = (props: IGridAttachments) => {
  const [data, setData] = React.useState<IDataAttachment[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataAttachment>(data[0]);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [isLoadingAllGridRows, setLoadingAllGridRows] = React.useState<boolean>(false);
  const [allPageRows, setAllPageRows] = React.useState<any[][]>([]);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const csvLink = React.useRef<any | null>();

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params]);

  React.useEffect(() => {
    if (!selected) {
      setSelected(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function load() {
    setLoading(true);
    try {
      const resp = await Sku_Api.listAttachments({
        q: search,
        page: page + 1,
        per_page: pageSize,
        ...params,
      });
      setData(resp.data);
      setTotal(resp.pagination.total);
      setTotalPages(resp.pagination.total_pages);
      if (!selected) {
        setSelected(rowToSelect === 'last' ? resp.data[resp.data.length - 1] : resp.data[0]);
      }
    } catch (error: IDataAttachmentErrors | unknown) {
      if (error) {
        const err = error as IDataAttachmentErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: 'Remover Arquivo',
      message: (
        <span>
          Tem certeza que deseja remover arquivo{' '}
          <strong>
            {selected?.id} -{' '}
            {selected && selected.file_name.length > 30
              ? `${selected?.file_name.substring(0, 30)}...`
              : selected?.file_name}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDeleteAttach(),
    });
  }

  async function handleDeleteAttach() {
    try {
      setLoading(true);
      await Sku_Api.detach(selected);
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataAttachmentErrors | unknown) {
      if (error) {
        const err = error as IDataAttachmentErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`,
            message: err.errors.message,
            onClose: () => setAlertModal({ ...alertModal, open: false }),
            onConfirm: () => setAlertModal({ ...alertModal, open: false }),
          });
      }
    } finally {
      setLoading(false);
      load();
    }
  }

  async function exportRows() {
    setLoading(true);
    try {
      await Sku_Api.exportMedia({
        q: search,
        ...params,
      });
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    } finally {
      setLoading(false);
    }
  }

  function onFilter(filters: IParamsQs) {
    setParams(filters);
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      options={{
        total: {
          value: total,
          total_pages: totalPages,
        },
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        isLoadingAllGridRows: isLoadingAllGridRows,
        isLoadingGrid: loading,
        data: data,
        selectedRow: selected,
      }}
      onClickAttach={props.onClickAttach ? props.onClickAttach : undefined}
      onImportClick={props.onImportClick ? props.onImportClick : undefined}
      onDeleteClick={() => setDeleteModal()}
      onClickRefresh={() => load()}
      searchValue={search}
      onSearchChange={(value) => setSearch(value)}
      exportGridData={() => exportRows()}
      navigate={(value) => {
        setSelected(value);
      }}
      navigateToPage={(page, rowToSelect = 'first') => {
        setRowToSelect(rowToSelect);
        setPage(page - 1);
      }}
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          <TableMUI
            initialState={{
              columns: {
                columnVisibilityModel: hiddenColumns,
              },
            }}
            density="comfortable"
            columns={columns}
            rows={data}
            page={page}
            rowCount={total}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            loading={loading}
            onRowClick={(row) => {
              setSelected(row.row);
            }}
            onSelectionModelChange={(newSelection) => {
              const row = data.filter((item) => item.id === newSelection[0]);
              setSelected(row[0]);
            }}
            selectionModel={[selected?.id as number]}
            customToolbar={() => <ToolbarComponent hasExportButton isGridRef />}
          />
        </Grid>
        <CSVLink
          data={allPageRows}
          target="_blank"
          ref={csvLink}
          filename="PRODUTO IMAGENS"
          separator=";"
        />
        <AlertModal
          open={alertModal?.open}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />

        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayProductImages;

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import Categories_Api from 'app/api/Categories_Api';
import AlertMessage from 'app/components/Alert';
import Toast from 'app/components/Toast';
import {
  CategoryExtraOptions,
  IDataCategory,
  IDataCategoryErrors,
} from 'app/types/data/IDataCategory';
import { IDataPartnerCategory } from 'app/types/data/IDataPartnerCategory';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CategorySchema } from '../@Schemas/CategorySchema';
import { IFormCategory } from '../@Types/IFormCategory';
//import PartnerCategories_Api from 'app/api/PartnerCategories_Api';
import SelectCategories from 'app/components/Inputs/Selects/SelectCategories/SelectCategories';
import SelectCategoriesProduct from 'app/components/Inputs/Selects/SelectCategoriesProduct/SelectCategoriesProduct';
import SelectTreeLibrary from 'app/components/Inputs/Selects/SelectTreeLibrary/SelectTreeLibrary';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import ProjectContext from 'app/contexts/ProjectContext';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { useToast } from 'app/hooks/useToast';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataCategory | IDataPartnerCategory = {
  id: null,
  parceiro_id: null,
  categoria_id: null,
  nm_categoria: '',
  alias: '',
  parent_id: null,
  nivel: '',
  classificacao: '',
  parent: null,
  select_option: { value: '', label: '' },
};

const UPDATE_MSG = getMessage('Categoria', 'update', 'a');
const CREATE_MSG = getMessage('Categoria', 'create', 'a');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialCombo: ISelectOption = {
  value: '',
  label: '',
};

const CategoryForm = (props: IFormCategory) => {
  const { resetFormList } = useUtilsContext();
  const projectCtxt = React.useContext(ProjectContext);
  const productCtxt = React.useContext(CatalogProductContext);
  //const segmentationCtxt = React.useContext(SegmentationContext);

  const [data, setData] = React.useState<IDataCategory | IDataPartnerCategory>(
    props.data || initialValues
  );
  const isFormHidden = data?.categorias && data?.categorias?.length >= 2;

  const [comboData /* setComboData */] = React.useState<
    ISelectOption<IDataCategory | IDataPartnerCategory>[]
  >([]);

  const [comboLoading /* setComboLoading */] = React.useState<boolean>(false);

  //const [search, setSearch] = React.useState<string>('');
  //const [searchUmami /* setSearchUmami */] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  const [segmentacao, setSegmentacao] = React.useState<number | undefined>(undefined);
  //const [canViewBaseUmami, setCanViewBaseUmami] = React.useState<boolean>(false);

  const [extraOptions /* setExtraOptions */] = React.useState<CategoryExtraOptions[]>([]);
  const { showToast } = useToast();

  useEffect(() => {
    setData((props.data as IDataCategory) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.categoriesForm]);

  /* useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]); */

  /*   useEffect(() => {
    if (projectCtxt) loadComboUmami();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCtxt, searchUmami, segmentacao]); */

  /* useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]); */

  React.useEffect(() => {
    if (projectCtxt) {
      setData({ ...data, projeto_id: projectCtxt.id as number, projeto: projectCtxt });
      setValue('projeto_id', projectCtxt.id as number);
      setValue('projeto', projectCtxt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCtxt]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataCategory | IDataPartnerCategory>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(CategorySchema),
    context: { isFormHidden },
  });

  useEffect(() => {
    setDataValue(data, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(dataForm: IDataCategory | IDataPartnerCategory) {
    const shallowCopy = { ...dataForm };
    setLoading(true);
    try {
      var newData: IDataCategory | IDataPartnerCategory;
      dataForm.projeto_id = projectCtxt ? (projectCtxt.id as number) : undefined;
      if (dataForm.id) {
        newData = await Categories_Api.update(dataForm, props.relation);
      } else {
        newData = await Categories_Api.create(dataForm, props.relation, extraOptions);
        if (props.onSubmit) props.onSubmit(newData);
      }

      setData(newData);
      setDataValue(newData, setValue);
      showToast({
        open: true,
        message: props.type === 'update' && shallowCopy?.id ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataCategoryErrors | unknown) {
      if (error) {
        const err = error as IDataCategoryErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormBox id="formik-Category" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>
        {projectCtxt && !productCtxt && !data.id && (
          <Grid item xs={12} marginBottom={3}>
            <SelectTreeLibrary
              //relation={projectCtxt && !segmentationCtxt ? props.relation : undefined}
              onChange={(segmentation) => segmentation && setSegmentacao(segmentation.id as number)}
            />
          </Grid>
        )}
        {projectCtxt && !productCtxt && !data.id && (
          <>
            <Grid item xs={12} marginBottom={3}>
              <SelectCategories
                id="categoria_id"
                label="Categorias"
                segmentacao_id={segmentacao}
                onChange={(categorie, data) => {
                  if (categorie) {
                    if (categorie?.length === 1 && data) {
                      setDataValue(
                        {
                          ...data[0],
                          id: null,
                          categoria_id: categorie?.[0] as number,
                        },
                        setValue
                      );
                      setData({
                        ...data[0],
                        id: null,
                        categoria_id: categorie?.[0] as number,
                      });
                    } else {
                      setDataValue(initialValues, setValue);
                      setData(initialValues);
                    }
                    setData((prev) => ({ ...prev, categorias: categorie }));
                  } else {
                    setData(initialValues);
                    setDataValue(initialValues, setValue);
                  }
                }}
                //onChangeExtraOptions={(options) => setExtraOptions(options)}
              />
            </Grid>
            <Divider sx={{ py: 2 }} />
          </>
        )}

        {productCtxt /* && canViewBaseUmami */ && (
          <>
            <Grid item xs={12} marginBottom={3}>
              <SelectCategoriesProduct
                id="categoria_id"
                label="Categorias"
                onChange={(categorie) => {
                  if (categorie) {
                    setData(categorie);
                    setDataValue(categorie, setValue);
                  } else {
                    setData(initialValues);
                    setDataValue(initialValues, setValue);
                  }
                }}
                relation={
                  productCtxt && projectCtxt
                    ? { id: projectCtxt.id as number, relation: 'Project' }
                    : undefined
                }
                //onChangeExtraOptions={(options) => setExtraOptions(options)}
              />
            </Grid>
            <Divider sx={{ py: 2 }} />
          </>
        )}

        {!isFormHidden && (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                name="parent_id"
                control={control}
                defaultValue={data?.parent_id}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    loading={comboLoading}
                    noOptionsText="Nenhuma categoria"
                    loadingText="Carregando categorias..."
                    options={comboData}
                    //onClose={() => setSearch('')}
                    defaultValue={initialCombo}
                    value={data.parent?.select_option || { value: '', label: '' }}
                    //groupBy={(option) => option.nivel || '1'}
                    //getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionDisabled={(option) => option?.data.id === data?.id}
                    onChange={(event, option: any) => {
                      if (option) {
                        setData({
                          ...data,
                          parent_id: option.value,
                          parent: {
                            id: option.value,
                            nm_categoria: option.label,
                            select_option: option,
                          },
                        });
                        onChange(option.value);
                      } else {
                        setData({ ...data, parent_id: null, parent: null });
                        onChange(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TimedTextField
                        {...params}
                        id="parent_id"
                        label="Categoria Pai"
                        type="text"
                        size="small"
                        //onChange={(e) => setSearch(e.target.value)}
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {errors.parent_id?.message}
                          </Typography>
                        }
                      />
                    )}
                    renderOption={(props, option) => {
                      const ident = (option.nivel?.toString().match(/\./g) || []).length;

                      return (
                        <li style={{ padding: 8, paddingLeft: 16 }} {...props}>
                          <span style={{ paddingLeft: 8 * ident }}>
                            <strong>{option.nivel}</strong>. {option.label}
                          </span>
                        </li>
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={2}>
              <Controller
                name="id"
                control={control}
                defaultValue={data?.id}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-category-id"
                    label="ID"
                    type="text"
                    disabled={true}
                    value={data.id}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: Boolean(data.id) }}
                    onChange={(e) => {
                      setData({ ...data, id: parseInt(e.target.value) });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <Controller
                name="alias"
                control={control}
                defaultValue={data?.alias}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="alias"
                    label="Alias Interno"
                    type="text"
                    value={data.alias}
                    autoComplete={'off'}
                    fullWidth
                    inputProps={{
                      maxLength: 191,
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.alias?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, alias: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="nm_categoria"
                control={control}
                defaultValue={data?.nm_categoria}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="nm_categoria"
                    label="Nome*"
                    type="text"
                    value={data.nm_categoria}
                    autoComplete={'off'}
                    fullWidth
                    inputProps={{
                      maxLength: 191,
                    }}
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.nm_categoria?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, nm_categoria: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
            {productCtxt && (
              <Grid item xs={12}>
                <Controller
                  name="main"
                  control={control}
                  defaultValue={data.main}
                  render={({ field: { onChange } }) => (
                    <FormControlLabel
                      label="Categoria principal do produto"
                      control={
                        <Checkbox
                          value={data.main}
                          checked={data.main}
                          onChange={(e) => {
                            setData({ ...data, main: e.target.checked });
                            onChange(e);
                          }}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Voltar
        </Button>
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
          Salvar
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default CategoryForm;

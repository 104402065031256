import { Grid } from '@mui/material';
import Categories_Api from 'app/api/Categories_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import CsvExcelModalImport from 'app/components/Modal/CsvExcelModalImport';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import {
  CategoryRelations,
  IDataCategory,
  IDataCategoryErrors,
} from 'app/types/data/IDataCategory';
import filterObjectColumns from 'app/utils/filterObjectColumns';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import TreeViewCategories from 'app/views/curated/category/components/CategoriesTreeView';
import columns from 'app/views/curated/category/components/DisplayCategories/Columns/GridColumns';
import hiddenColumns from 'app/views/curated/category/components/DisplayCategories/Columns/hiddenColumns';
import React, { Fragment, SetStateAction } from 'react';
import { CSVLink } from 'react-csv';
import CategoryFormContainer from '../../ProductTreeFormContainer';

const header = columns.map((item) => item.field);

const UPDATE_MSG = getMessage('Categoria', 'update', 'a');
const DELETE_MSG = getMessage('Categoria', 'delete', 'a');

const initialValues: IDataCategory = {
  id: null,
  nm_categoria: '',
  parent_id: null,
  nivel: '',
  classificacao: '',
  parent: null,
  select_option: { value: '', label: '' },
};

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplayCategory {
  view?: 'tree' | 'grid';
  relation?: IDataRelation<CategoryRelations>;
}

const initialProductImportData = {
  data: null,
  loading: false,
};

const DisplayProductTree = (props: IDisplayCategory) => {
  const { handleResetForm } = useUtilsContext();
  const [isGridView, toggleView] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [dataGrid, setDataGrid] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataCategory | undefined>(undefined);
  const [created, setCreated] = React.useState<IDataCategory | undefined>(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [rowStopReason, setRowStopReason] = React.useState<string>();
  const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [isLoadingAllGridRows, setLoadingAllGridRows] = React.useState<boolean>(false);
  const [allPageRows, setAllPageRows] = React.useState<any[][]>([]);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const [importProductData, setImportProductData] = React.useState(initialProductImportData);
  const [productImportModal, setProductImportModal] = React.useState<boolean>(false);
  const csvLink = React.useRef<any | null>();

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    if (isGridView) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, isGridView]);

  async function load(itemToBeSelected?) {
    setLoading(true);
    try {
      const Categories = await Categories_Api.list(
        { q: search, page: page + 1, per_page: pageSize, is_null: 'projeto_id', ...params },
        props.relation
      );
      setDataGrid(Categories.data);
      const filteredData = Categories.data.map((item, index) => {
        return {
          id: item.id,
          parent: item.parent_id || 0,
          text: item.nm_categoria,
          droppable: true,
          data: item,
        };
      });
      setData(filteredData);
      setTotal(Categories.pagination.total);
      setTotalPages(Categories.pagination.total_pages);
      setSelected(
        rowToSelect === 'last'
          ? Categories.data[Categories.data.length - 1]
          : itemToBeSelected
          ? itemToBeSelected
          : Categories.data[0]
      );
    } catch (error: IDataCategoryErrors | unknown) {
      setLoading(false);
      if (error) {
        const err = error as IDataCategoryErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  async function handleUpdate(nm_categoria: string) {
    if (selected) {
      setLoading(true);
      try {
        await Categories_Api.update({ ...selected, nm_categoria }, props.relation);
        load();
        setToast({
          open: true,
          message: UPDATE_MSG,
          severity: initialToast.severity,
        });
      } catch (error: IDataCategoryErrors | unknown) {
        const err = error as IDataCategoryErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`,
            message: err.errors.message,
            onClose: () => setAlertModal({ ...alertModal, open: false }),
            onConfirm: () => setAlertModal({ ...alertModal, open: false }),
          });
      } finally {
        setLoading(false);
      }
    }
  }

  const processRowUpdate = async (newRow, oldRow) => {
    if (rowStopReason === 'enterKeyDown' || onSaveRow) {
      const response = await Categories_Api.update(newRow);
      load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
      setOnSaveRow(null);
      return response;
    }
    setOnSaveRow(null);
    return oldRow;
  };

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function handleDelete() {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (selected) await Categories_Api.delete(selected, props.relation);
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataCategoryErrors | unknown) {
      const err = error as IDataCategoryErrors;
      if (err.errors.status && err.errors.message)
        setAlertModal({
          open: true,
          title: `${err.errors.status} - Não foi possível prosseguir`,
          message: err.errors.message,
          onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
        });
    }
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: `${props.relation ? 'Retirar' : 'Remover'} categoria`,
      message: (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} a categoria{' '}
          <strong>
            {selected?.id} - {selected?.nm_categoria}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  function handleClickView() {
    if (!isGridView) setSelected(undefined);
    toggleView(!isGridView);
  }

  async function onImportProducts(file) {
    setImportProductData(initialProductImportData);
    try {
      setImportProductData((prev) => ({ ...prev, loading: true }));
      const resp = await Categories_Api.import(file);
      setImportProductData((prev) => ({ ...prev, data: resp, loading: false }));
    } catch (error) {
      setToast({
        open: true,
        message: 'Não foi possível importar o arquivo. Tente novamente mais tarde',
        severity: 'error',
      });
      setImportProductData((prev) => ({ ...prev, data: null, loading: false }));
    }
  }

  async function loadAllGridRows() {
    try {
      setLoadingAllGridRows(true);
      const items = await Categories_Api.list(
        { q: search, page: page, per_page: total, is_null: 'projeto_id', ...params },
        props.relation
      );
      items.data.forEach((item) => {
        item['created_at'] = formatDateBR(item['created_at']);
        item['updated_at'] = formatDateBR(item['updated_at']);
      });
      const fields = columns.map((item) => item.field);
      const result: SetStateAction<any[][]> = [
        fields,
        ...items.data.map((obj) => fields.map((key) => obj[key])),
      ];
      const filteredResult = filterObjectColumns(result, 'nm_categoria');
      filteredResult[0] = header;
      setAllPageRows(filteredResult);
      setLoadingAllGridRows(false);
      csvLink?.current?.link?.click();
    } catch (error: IDataCategoryErrors | unknown) {
      setLoadingAllGridRows(false);
      if (error) {
        const err = error as IDataCategoryErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    }
  }

  function onFilter(filters: IParamsQs) {
    setParams(filters);
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      options={{
        view: {
          state: isGridView ? 'grid' : 'form',
          //disabled: props.view === 'grid' ? false : !Boolean(selected),
        },
        cancel: {
          onClick: props.view !== 'grid' ? () => setSelected(undefined) : undefined,
        },
        total: {
          value: total,
          total_pages: totalPages,
        },
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        isLoadingAllGridRows: isLoadingAllGridRows,
        isLoadingGrid: loading,
        data: data,
        selectedRow: selected,
      }}
      onAddClick={() => {
        toggleView(false);
        setCreated({ ...initialValues, parent_id: selected?.id || null, parent: selected });
        setSelected(undefined);
      }}
      onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
      onClickView={handleClickView}
      onClickRefresh={() => load()}
      onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
      onSearchChange={(value) => setSearch(value)}
      onResetForm={() => handleResetForm('categoriesForm')}
      onSaveRow={() => setOnSaveRow('save')}
      onFileImport={() => setProductImportModal(true)}
      exportGridData={() => loadAllGridRows()}
      navigate={(value) => {
        setSelected(value);
      }}
      navigateToPage={(page, rowToSelect = 'first') => {
        setRowToSelect(rowToSelect);
        setPage(page - 1);
      }}
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          {!isGridView && <ToolbarComponent />}
          {isGridView && props.view === 'grid' && (
            <TableMUI
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns,
                },
              }}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  toggleView(false);
                }
              }}
              columns={columns}
              rows={dataGrid}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => {
                setSelected(undefined);
                setPage(newPage);
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                const row = data.filter((item) => item.id === newSelection[0]);
                setSelected(row[0]?.data || row[0]);
              }}
              selectionModel={[selected?.id as number]}
              customToolbar={() => <ToolbarComponent hasExportButton isGridRef />}
            />
          )}
          <CSVLink
            data={allPageRows}
            target="_blank"
            ref={csvLink}
            filename="Árvore de Categorias"
            separator=";"
          />
          {isGridView && props.view !== 'grid' && (
            <TreeViewCategories
              loading={loading}
              nodeSelected={
                selected
                  ? {
                      id: selected.id as number,
                      text: selected.nm_categoria,
                      parent: selected.parent_id as number,
                      data: selected,
                    }
                  : undefined
              }
              clearSelected={!Boolean(selected)}
              onClickNode={(node) => setSelected(node.data)}
              data={data}
              onDrop={() => load()}
              onUpdate={(node, newValue) => handleUpdate(newValue)}
            />
          )}
          {!isGridView && (
            <CategoryFormContainer
              setSelected={(data) => setSelected(data)}
              relation={props.relation}
              data={selected || created}
              onSubmit={(itemToBeSelected) => load(itemToBeSelected)}
              cancel={() => toggleView(!isGridView)}
            />
          )}
        </Grid>
        <CsvExcelModalImport
          data={importProductData?.data}
          fileModel="/api/storage/_modelos_importacao/Layout_Importação_Produtos_UMAMI.zip"
          onSubmit={(files) => onImportProducts(files[0])}
          loading={importProductData?.loading}
          title="Importar categorias"
          open={productImportModal}
          onClose={() => {
            setProductImportModal(false);
            setImportProductData(initialProductImportData);
          }}
          onCancel={() => {
            setProductImportModal(false);
            setImportProductData(initialProductImportData);
          }}
        />
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayProductTree;

import { Autocomplete, TextField } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { IDataIntegrator } from 'app/types/data/IDataIntegrator';
import { IDataProduct } from 'app/types/data/IDataProduct';
import { IDataUser } from 'app/types/data/IDataUser';
import { formatDateBR, moneyBR } from 'app/utils/format';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'sku',
    headerName: 'SKU',
    flex: 1,
    //width: 150,
    editable: true,
    type: 'string',
  },
  {
    field: 'name',
    headerName: 'Nome ',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'produto',
    headerName: 'Produto',
    hideable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataProduct>) =>
      params.value ? params.value.nm_produto : '',
  },
  {
    field: 'short_name',
    headerName: 'Nome reduzido',
    flex: 1,
    editable: true,
  },
  {
    field: 'nr_ean',
    headerName: 'Cód. Barras',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'vl_precode',
    headerName: 'Preço de',
    hideable: true,
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'vl_precopor',
    headerName: 'Preço por',
    hideable: true,
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_estoque',
    headerName: 'Estoque',
    hideable: true,
    editable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_estoque_minimo',
    headerName: 'Estoque Mínimo',
    hideable: true,
    editable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_largura',
    headerName: 'Largura',
    hideable: true,
    editable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_altura',
    headerName: 'Altura',
    hideable: true,
    editable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_profundidade',
    headerName: 'Profundidade',
    hideable: true,
    editable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_peso',
    headerName: 'Peso',
    hideable: true,
    editable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_largura_logistica',
    headerName: 'Largura Logistica',
    hideable: true,
    editable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_altura_logistica',
    headerName: 'Altura Logistica',
    editable: true,
    hideable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_profundidade_logistica',
    headerName: 'Profundidade Logistica',
    editable: true,
    hideable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'nr_peso_logistica',
    headerName: 'Peso Logistica',
    editable: true,
    hideable: true,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value ? moneyBR(params.value) : null,
  },
  {
    field: 'vitrine_virtual',
    headerName: 'Vitrine Virtual',
    hideable: true,
    type: 'boolean',
  },
  {
    field: 'tags',
    headerName: 'Tags',
    hideable: true,
    valueFormatter: (params: GridValueFormatterParams<string[]>) =>
      params.value ? params.value.join(', ') : null,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        value={params.value || null}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Tags*" fullWidth size="small" />
        )}
      />
    ),
  },
  {
    field: 'status_umami',
    headerName: 'Status Umami',
    hideable: true,
    type: 'singleSelect',
    valueOptions: [
      { value: '1', label: 'Ativo' },
      { value: '2', label: 'Inativo' },
      { value: '3', label: 'Pausado' },
      { value: '4', label: 'Entregue' },
      { value: '5', label: 'Ativo com prioridade' },
    ],
    renderCell: (param: GridRenderCellParams) => (
      <span>{param?.row?.status_umami?.label || ''}</span>
    ),
  },
  {
    field: 'integration_status',
    headerName: 'Integração Status',
    hideable: true,
    type: 'singleSelect',
    valueOptions: [
      { value: 'new', label: 'Produto Novo' },
      { value: 'updated_price', label: 'Produto Atualizado Preço' },
      { value: 'updated_stock', label: 'Produto Atualizado Estoque' },
      { value: 'updated_price_stock', label: 'Produto Atualizado Preço e Estoque' },
    ],
  },
  {
    field: 'integration_situation',
    headerName: 'Integração Situação',
    hideable: true,
    type: 'singleSelect',
    valueOptions: [
      { value: 'received', label: 'Recebido' },
      { value: 'imported_worksheet', label: 'Importado via planilha' },
      { value: 'sent', label: 'Enviado' },
      { value: 'exported_worksheet', label: 'Exportado via planilha' },
    ],
  },
  {
    field: 'quality_status',
    headerName: 'Qualidade Status',
    hideable: true,
    type: 'singleSelect',
    valueOptions: [
      { value: '1', label: 'Pré-cadastro iniciado' },
      { value: '2', label: 'Pré-cadastro finalizado' },
      { value: '3', label: 'Pré-cadastro finalizado' },
      { value: '4', label: 'Ajuste necessário (Qualidade)' },
      { value: '5', label: 'Finalizado Qualidade' },
    ],
    renderCell: (param: GridRenderCellParams) => (
      <span>{param?.row?.quality_status?.label || ''}</span>
    ),
  },
  {
    field: 'integrador_id',
    headerName: 'Integrador ID',
    hideable: true,
  },
  {
    field: 'integrador',
    headerName: 'Integrador',
    hideable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataIntegrator>) =>
      params.value ? params.value.nm_integrador : '',
  },
  {
    field: 'integrador_code',
    headerName: 'Cód. Integrador',
    hideable: true,
  },
  {
    field: 'integrated_at',
    headerName: 'Integrado em',
    hideable: true,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      params.value ? formatDateBR(params.value) : null,
  },
  {
    field: 'curador_id',
    headerName: 'Curador ID',
    hideable: true,
  },
  {
    field: 'curador',
    headerName: 'Curador',
    hideable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'origin_id',
    type: 'number',
    headerName: 'Origem ID',
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user_id',
    headerName: 'Atualizador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

export default columns;

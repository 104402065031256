export const navigations = [
  //{ name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  {
    name: 'Gestão',
    icon: 'settings',
    children: [
      {
        name: 'Configurador',
        icon: 'sports_esports',
        children: [
          { name: 'Usuários', icon: 'person', path: '/gestao/usuarios', iconText: 'U' },
          {
            name: 'Grupos de Usuários',
            icon: 'group',
            path: '/gestao/grupo-usuarios',
            iconText: 'G',
          },
        ],
      },
      { name: 'Dashboard', icon: 'leaderboard', path: '/pagina-em-construcao' },
      { name: 'Relatório', icon: 'summarize', path: '/pagina-em-construcao' },
    ],
  },
  {
    name: 'Curadoria',
    icon: 'filter_alt',
    children: [
      { name: 'Catálogo de Produtos', icon: 'qr_code', path: '/curadoria/catalogo-produtos' },
      { name: 'SKUs', icon: 'label_icon', path: '/curadoria/skus' },
      /* {
        name: 'Árvore de Categorias',
        icon: 'account_tree',
        path: '/curadoria/arvore-de-categorias',
      }, */
      //{ name: 'Produtos x Parceiros', icon: 'lan', path: '/curadoria/produtos-parceiros' },
      {
        name: 'Biblioteca de Árvores',
        icon: 'library_books_icon',
        path: '/curadoria/biblioteca-arvore',
      },
      {
        name: 'Categorias',
        icon: 'schema_icon',
        path: '/curadoria/categorias',
      },
      { name: 'Produtos x Imagens', icon: 'collections', path: '/curadoria/produtos-imagens' },
      {
        name: 'Cadastros Especiais',
        icon: 'app_registration',
        path: '/curadoria/cadastros-especiais',
        children: [
          {
            name: 'Orientações',
            icon: 'summarize',
            path: '/curadoria/cadastros-especiais/orientacoes',
          },
          /* {
            name: 'Categorias',
            icon: 'category',
            path: '/curadoria/cadastros-especiais/categorias',
          }, */
          {
            name: 'Marcas',
            icon: 'branding_watermark',
            path: '/curadoria/cadastros-especiais/marcas',
          },
          {
            name: 'Unidades',
            icon: 'monitor_weight',
            path: '/curadoria/cadastros-especiais/unidades',
          },
          {
            name: 'Atributos',
            icon: 'fact_check',
            path: '/curadoria/cadastros-especiais/atributos',
          },
          {
            name: 'Canais de vendas',
            icon: 'monetization_on_icon',
            path: '/curadoria/cadastros-especiais/canais-de-vendas',
          },

          {
            name: 'Modelo de Segmentação',
            icon: 'hub',
            path: '/curadoria/cadastros-especiais/modelo-segmentacao',
          },
          {
            name: 'Segmentos',
            icon: 'linear_scale_icon',
            path: '/curadoria/cadastros-especiais/segmentos',
          },
          {
            name: 'Tipos de Segmentação',
            icon: 'scatter_plot_icon',
            path: '/curadoria/cadastros-especiais/tipos-segmentacao',
          },
          {
            name: 'Tipo de Serviço',
            icon: 'business_center_icon',
            path: '/curadoria/cadastros-especiais/tipo-servico',
          },
          {
            name: 'Tipos de Motivo',
            icon: 'psychology_alt_icon',
            path: '/curadoria/cadastros-especiais/tipos-motivo',
          },
          {
            name: 'Motivos',
            icon: 'emoji_objects_icon',
            path: '/curadoria/cadastros-especiais/motivos',
          },
          {
            name: 'Status da Loja',
            icon: 'find_in_page_icon',
            path: '/curadoria/cadastros-especiais/status-loja',
          },
        ],
      },
    ],
  },
  {
    name: 'Parceiros',
    icon: 'handshake',
    children: [
      { name: 'Clientes', icon: 'groups_3', path: '/parceiros/clientes' },
      { name: 'Projetos', icon: 'lightbulb', path: '/parceiros/projetos' },
      { name: 'Sellers Center', icon: 'hub', path: '/parceiros/sellers-center' },
      { name: 'Shoppings', icon: 'location_city', path: '/parceiros/shoppings' },
      { name: 'Lojas', icon: 'shopping_cart', path: '/parceiros/lojas' },
      { name: 'Marketplace', icon: 'shopping_bag', path: '/parceiros/marketplace' },
      { name: 'Integradores', icon: 'business', path: '/parceiros/integradores' },
      { name: 'Sistemas', icon: 'wysiwyg', path: '/parceiros/sistemas' },
      { name: 'Workflow', icon: 'list_alt', path: '/parceiros/workflow' },
    ],
  },
  {
    name: 'Portais',
    icon: 'computer',
    children: [
      { name: 'Catálogo de Produtos', icon: 'view_list', path: '/pagina-em-construcao' },
      { name: 'Vitrine Virtual', icon: 'storefront', path: '/portal/vitrine-virtual' },
      { name: 'Dashboard', icon: 'leaderboard', path: '/pagina-em-construcao' },
      { name: 'Relatório', icon: 'summarize', path: '/pagina-em-construcao' },
    ],
  },
  {
    name: 'Comunicação',
    icon: 'forum',
    children: [
      { name: 'Push', icon: 'edgesensor_low', path: '/pagina-em-construcao' },
      { name: 'E-mail', icon: 'alternate_email', path: '/pagina-em-construcao' },
      { name: 'SMS', icon: 'sms', path: '/pagina-em-construcao' },
      { name: 'Robô Umami', icon: 'smart_toy', path: '/pagina-em-construcao' },
    ],
  },
  {
    name: 'UMAM.IA',
    icon: 'psychology',
    path: '/pagina-em-construcao',
  },
  {
    name: 'Integrações',
    icon: 'widgets',
    children: [
      { name: 'Importação/Exportação', icon: 'import_export', path: '/pagina-em-construcao' },
      { name: 'EDI/API', icon: 'api', path: '/pagina-em-construcao' },
    ],
  },
];

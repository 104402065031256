import React from "react"
import { Icon, IconButton, TextField } from "@mui/material"
import { useDebounce } from "use-debounce"

declare interface ISearchInput {
 value?: string
 debounceTimeout?: number
 minLength?: number
 onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
 onDebounceValueChange: (value: string) => void
}

const SearchInput = (props: ISearchInput) => {
 const min = props.minLength || 0;
 const [val, setVal] = React.useState(props.value || "");
 const [debounceValue] = useDebounce(val, props.debounceTimeout || 500);

 React.useEffect(() => {
  if (props.value) setVal(props.value);
 }, [props.value]);

 React.useEffect(() => {
  if (props.onDebounceValueChange) {
   if (Boolean(debounceValue) && debounceValue.length >= min) {
    props.onDebounceValueChange(debounceValue);
   } else if (!Boolean(debounceValue)) {
    props.onDebounceValueChange('');
   }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [debounceValue]);

 return (
  <TextField
   sx={{
    '& .MuiInputBase-input': { padding: 0, paddingLeft: '10px' },
    '& .MuiInputBase-root': { padding: 0 },
    mr: 2,
   }}
   id="standard-bare"
   value={val}
   onChange={(e) => {
    setVal(e.target.value);
    if (props.onChange) props.onChange(e);
   }}
   variant="standard"
   placeholder="Pesquisar registros..."
   size="small"
   InputProps={{
    endAdornment: (
     <IconButton>
      <Icon>search</Icon>
     </IconButton>
    ),
   }}
  />
 )
}

export default SearchInput

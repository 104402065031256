import { Fragment } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import propTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function FormatEAN(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="#############"
    />
  );
}

FormatEAN.propTypes = {
  inputRef: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

const EanInput = (props: TextFieldProps) => {
  return (
    <Fragment>
      <TextField
        InputProps={{ inputComponent: FormatEAN }}
        {...props}
      />
    </Fragment>
  );
}

export default EanInput;
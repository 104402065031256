import { Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Sku_Api from 'app/api/Sku_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import CsvExcelModalImport from 'app/components/Modal/CsvExcelModalImport';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import StoreContext from 'app/contexts/StoreContext';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { useToast } from 'app/hooks/useToast';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IStatusOptions } from 'app/types/IStatusOptions';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import { IDataSku, IDataSkuErrors, SkuRelations } from 'app/types/data/IDataSku';
import { getMessage } from 'app/utils/messages';
import React, { Fragment } from 'react';
import SkuCreateAttachment from '../SkuCreateAttachment';
import SkuFormContainer from '../SkuFormContainer';
import GridColumns from './Columns/GridColumns';
import hiddenColumns from './Columns/hiddenColumns';

const TableMUIComponent = styled(TableMUI)(() => ({
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    //display: 'none',
  },
}));

//const header = GridColumns.map((item) => item.field);

const UPDATE_MSG = getMessage('Sku', 'update');
const DELETE_MSG = getMessage('Sku', 'delete');
const DELETE_MULT_MSG = getMessage('Skus', 'delete', 'os');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplaySku {
  relation?: IDataRelation<SkuRelations>;
}

const initialSkuImportData = {
  data: null,
  loading: false,
};

const DisplaySku = (props: IDisplaySku) => {
  const { handleResetForm } = useUtilsContext();
  const storeContext = React.useContext(StoreContext);
  const productCtxt = React.useContext(CatalogProductContext);

  const [isGridView, toggleView] = React.useState(true);
  const [data, setData] = React.useState<IDataSku[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataSku | undefined>(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [openAttach, toggleAttachModal] = React.useState<boolean>(false);
  const [rowStopReason, setRowStopReason] = React.useState<string>();
  const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [productImportModal, setSkuImportModal] = React.useState<boolean>(false);
  const [importSkuData, setImportSkuData] = React.useState(initialSkuImportData);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const [multipleSelected, setMultipleSelected] = React.useState<string[] | number[]>([]);
  const [isCheckboxOn, setIsCheckboxOn] = React.useState<boolean>(false);
  const [isMassCuratorLoading, setIsMassCuratorLoading] = React.useState<boolean>(false);
  const [isAllPageRowsSelected, selectAllPageRows] = React.useState<boolean | null>(false);
  const [wasSelectAllSkuClicked, setWasSelectAllSkuClicked] = React.useState<boolean>(false);
  const [reloadMedia, setReloadMedia] = React.useState<boolean>(false);
  //const [customModalOpen, setCustomModalOpen] = React.useState(false);

  // Usado para verificar se a grid ja está com os valores filtrados pela origem
  const [isGridFilteredByNotInBase, setIsGridFilteredByNotInBase] = React.useState(false);

  // Status da request de filtrar por produtos fora da base umami
  const [notInBaseStatus, setNotInBaseStatus] = React.useState<IStatusOptions>('idle');

  const [isCopyingToBase, setIsCopyingToBase] = React.useState(false);

  const { showToast } = useToast();

  const { uuid } = useTrailHistory({
    selected,
    selectedName: selected?.name,
    displayName: 'SKUs',
    toggleView,
  });

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    if (isGridView) {
      load(isGridFilteredByNotInBase);
    } else {
      setIsGridFilteredByNotInBase(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, isGridView]);

  React.useEffect(() => {
    if (!isCheckboxOn) {
      setMultipleSelected([]);
      selectAllPageRows(false);
    }
  }, [isCheckboxOn]);

  async function load(notInBase = false) {
    setLoading(true);
    try {
      var Skus;
      if (!notInBase) {
        Skus = await Sku_Api.list(
          {
            q: search,
            page: page + 1,
            per_page: pageSize,
            ...params,
          },
          props.relation
        );
      } else {
        setNotInBaseStatus('pending');
        Skus = await Sku_Api.listWithNoAgnostic(
          {
            q: search,
            page: page + 1,
            per_page: pageSize,
            ...params,
          },
          props.relation
        );
        setNotInBaseStatus('resolved');
        setIsGridFilteredByNotInBase(true);
      }
      if (isCheckboxOn) {
        setMultipleSelected(Skus?.data?.map((item) => item.id as string) || []);
        setWasSelectAllSkuClicked(false);
      }
      setData(Skus.data);
      setTotal(Skus.pagination.total);
      setTotalPages(Skus.pagination.total_pages);
      if (!selected) {
        setSelected(rowToSelect === 'last' ? Skus.data[Skus.data.length - 1] : Skus.data[0]);
      } else {
        const sel = Skus.data.filter((item) => item.id === selected.id)[0];
        setSelected(sel);
      }
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  const processRowUpdate = async (newRow, oldRow) => {
    if (rowStopReason === 'enterKeyDown' || onSaveRow) {
      const response = await Sku_Api.update(newRow);
      load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
      setOnSaveRow(null);
      return response;
    }
    setOnSaveRow(null);
    return oldRow;
  };

  function handleCloseToast() {
    setToast({ open: false, ...initialToast });
  }

  async function handleDelete() {
    try {
      const hasMultipleDelete = multipleSelected.length > 0;
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (hasMultipleDelete) await Sku_Api.deleteMany(multipleSelected, props.relation);
      else if (selected) await Sku_Api.delete(selected, props.relation);
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({
        open: true,
        message: hasMultipleDelete ? DELETE_MULT_MSG : DELETE_MSG,
        severity: initialToast.severity,
      });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataSkuErrors | unknown) {
      const err = error as IDataSkuErrors;
      if (err.errors.status && err.errors.message)
        setAlertModal({
          open: true,
          title: `${err.errors.status} - Não foi possível prosseguir`,
          message: err.errors.message,
          onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
        });
    }
  }

  function setDeleteModal() {
    let title = `${props.relation ? 'Retirar' : 'Remover'} SKU`;
    let msg = (
      <span>
        Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} o sku{' '}
        <strong>
          {selected?.id} - {selected?.name}
        </strong>
        ?
      </span>
    );

    if (multipleSelected.length > 0) {
      title = `${props.relation ? 'Retirar' : 'Remover'} SKUs`;
      msg = (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'}{' '}
          <strong>todos os SKUs selecionados</strong>?
        </span>
      );
    }

    setAlertModal({
      open: true,
      title: title,
      message: msg,
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  const handleCurate = async () => {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (!!multipleSelected.length)
        await Sku_Api.curate({ loja_id: storeContext?.id as string, id: multipleSelected });
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({
        open: true,
        message: 'Skus curados com sucesso!',
        severity: initialToast.severity,
      });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataSkuErrors | unknown) {
      const err = error as IDataSkuErrors;
      if (err.errors.status && err.errors.message)
        setAlertModal({
          open: true,
          title: `${err.errors.status} - Não foi possível prosseguir`,
          message: err.errors.message,
          onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
        });
    }
  };

  const setCurateModal = () => {
    setAlertModal({
      open: true,
      title: 'Curar sku',
      message: <span>Deseja curar esses Skus?</span>,
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleCurate(),
    });
  };

  function handleUpload() {
    setReloadMedia((prev) => !prev);
  }

  async function onImportSkus(file) {
    setImportSkuData(initialSkuImportData);
    try {
      setImportSkuData((prev) => ({ ...prev, loading: true }));
      const resp = await Sku_Api.import(file, props.relation);
      load();
      //setSkuImportModal(false);
      setImportSkuData((prev) => ({ ...prev, data: resp, loading: false }));
    } catch (error) {
      setImportSkuData((prev) => ({ ...prev, data: null, loading: false }));
      setToast({
        open: true,
        message: 'Não foi possível importar os SKUs. Tente novamente',
        severity: 'error',
      });
    }
  }

  function onFilter(filters: IParamsQs) {
    setParams(filters);
    setWasSelectAllSkuClicked(false);
    setIsMassCuratorLoading(false);
    setIsCheckboxOn(false);
    setMultipleSelected([]);
    selectAllPageRows(false);
  }

  const saveMassCurator = async (curatorId) => {
    try {
      setIsMassCuratorLoading(true);
      await Sku_Api.batch(
        {
          products:
            isAllPageRowsSelected || multipleSelected?.length === total
              ? 'all_filter'
              : multipleSelected,
          curator_id: curatorId,
        },
        {
          ...params,
          q: search,
        }
      );
      setToast({
        open: true,
        message: 'Skus atualizados com sucesso!',
        severity: initialToast.severity,
      });
      setIsMassCuratorLoading(false);
      setIsCheckboxOn(false);
      selectAllPageRows(false);
      load();
    } catch (error) {
      setToast({
        open: true,
        message: 'Não foi possível atualizar os skus. Tente novamente',
        severity: 'error',
      });
      setWasSelectAllSkuClicked(false);
      setIsMassCuratorLoading(false);
      setIsCheckboxOn(false);
      setMultipleSelected([]);
      selectAllPageRows(false);
    }
  };

  async function doExport() {
    setLoading(true);
    try {
      await Sku_Api.export(
        {
          q: search,
          page: 1,
          per_page: total,
          ...params,
        },
        props.relation
      );
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      setLoading(false);
    }
  }

  const copySkusToBase = async (relationsToCopy: Record<string, string[]>) => {
    if (!storeContext?.id) return;
    try {
      setIsCopyingToBase(true);
      await Sku_Api.copyToBase(multipleSelected, relationsToCopy, storeContext.id);
      showToast({
        open: true,
        message: 'SKUs copiados para base umami com sucesso!',
        severity: 'success',
      });
      setIsCopyingToBase(false);
      setIsCheckboxOn(false);
      load();
    } catch (error: IDataSkuErrors | unknown) {
      const err = error as IDataSkuErrors;
      showToast({
        open: true,
        message: err?.errors?.message || 'Não foi possível copiar os skus para base umami',
        severity: 'error',
      });
      setIsCopyingToBase(false);
      setIsCheckboxOn(false);
      load();
    }
  };

  const ToolbarSelectionMessage = () => (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {multipleSelected.length < data.length && (
        <span>{`${multipleSelected.length} registro${
          multipleSelected.length >= 2 ? 's' : ''
        } selecionado${multipleSelected.length >= 2 ? 's' : ''}.`}</span>
      )}
      {!wasSelectAllSkuClicked &&
        data?.length < total &&
        multipleSelected?.length > 0 &&
        multipleSelected?.length === data.length && (
          <span>{`Todos os ${multipleSelected.length} registros estão selecionados.`}</span>
        )}
      {!wasSelectAllSkuClicked &&
        data?.length === total &&
        multipleSelected?.length === total &&
        multipleSelected.length === data.length && (
          <span>{`Todos os ${total} registros estão selecionados.`}</span>
        )}
      {wasSelectAllSkuClicked && multipleSelected?.length === data?.length && (
        <span>{`Todos os ${total} registros estão selecionados.`}</span>
      )}
      {multipleSelected.length < total && !isAllPageRowsSelected && (
        <Button
          onClick={() => {
            setMultipleSelected(data.map((item) => item?.id as string));
            setWasSelectAllSkuClicked(true);
            selectAllPageRows(true);
          }}
        >
          {`Selecionar todos os ${total} registros`}
        </Button>
      )}
      {((wasSelectAllSkuClicked && data.length === multipleSelected.length) ||
        (data.length === total && multipleSelected.length === total)) && (
        <Button
          onClick={() => {
            setWasSelectAllSkuClicked(false);
            selectAllPageRows(false);
            setMultipleSelected([]);
            setIsCheckboxOn(false);
          }}
        >
          Limpar seleção
        </Button>
      )}
    </div>
  );

  const ToolbarComponent = (props) => (
    <>
      <Toolbar
        options={{
          total: {
            value: total,
            total_pages: totalPages,
          },
          view: {
            state: isGridView ? 'grid' : 'form',
            mass_curator: true,
            filter_by_agnostic: Boolean(storeContext),
            copy_to_base: Boolean(storeContext),
            sku_curate: Boolean(storeContext),
          },
          filter: {
            columns: productCtxt
              ? GridColumns.filter((cols) => cols.field !== 'produto')
              : GridColumns,
            onFilter: onFilter,
          },
          massCurator: {
            saveMassCurator: saveMassCurator,
            isMassCuratorLoading: isMassCuratorLoading,
            isAllPageRowsSelected: isAllPageRowsSelected,
          },
          filter_by_agnostic: {
            filterByAgnostic: () => load(true),
            isFilteringByAgnostic: notInBaseStatus === 'pending',
            isFilteringByAgnosticDisabled: isGridFilteredByNotInBase,
          },
          copy_to_base: {
            copyToBaseCallback: (relations) => copySkusToBase(relations),
            isCopyingToBase,
            relationsToCopyOptions: ['skus', 'produtos'],
          },
          page: page + 1,
          //isLoadingAllGridRows: isLoadingAllGridRows,
          isLoadingGrid: loading,
          data: data,
          isCheckboxOn: isCheckboxOn,
          multipleSelected: multipleSelected,
          selectedRow: selected,
        }}
        onAddClick={() => {
          toggleView(false);
          setSelected(undefined);
        }}
        onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
        onClickView={() => toggleView(!isGridView)}
        onClickRefresh={() => load()}
        onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
        //onClickAttach={() => toggleAttachModal(true)}
        searchValue={search}
        onSearchChange={(value) => setSearch(value)}
        onResetForm={() => handleResetForm('productForm')}
        onSkuCurate={props.relation ? undefined : () => setCurateModal()}
        onSaveRow={() => setOnSaveRow('save')}
        onFileImport={() => setSkuImportModal(true)}
        exportGridData={() => doExport()}
        navigate={(value) => {
          setSelected(value);
        }}
        navigateToPage={(page, rowToSelect = 'first') => {
          setRowToSelect(rowToSelect);
          setPage(page - 1);
        }}
        selectAllPageRows={(isAllPageRowsAlreadySelected) => {
          if (isAllPageRowsAlreadySelected === false) {
            setMultipleSelected([]);
          }
          setIsCheckboxOn((prev) => !prev);
          setMultipleSelected(data.map((item) => item.id as number));
        }}
        {...props}
      />
      {isCheckboxOn && !!multipleSelected?.length && !loading && <ToolbarSelectionMessage />}
    </>
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          {!isGridView && <ToolbarComponent />}
          {isGridView && (
            <TableMUIComponent
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns(!Boolean(productCtxt)),
                },
              }}
              checkboxSelection={isCheckboxOn}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  toggleView(false);
                }
              }}
              columns={GridColumns}
              rows={data}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => {
                setIsCheckboxOn(false);
                setWasSelectAllSkuClicked(false);
                setMultipleSelected([]);
                setPage(newPage);
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                if (isCheckboxOn) {
                  selectAllPageRows(null);
                  setMultipleSelected(newSelection as string[]);
                }
                const row = data.filter((item) => item.id === newSelection[0]);
                setSelected(row[0]);
              }}
              selectionModel={isCheckboxOn ? multipleSelected : [selected?.id as number]}
              customToolbar={() => <ToolbarComponent hasExportButton isGridRef />}
            />
          )}

          {!isGridView && (
            <SkuFormContainer
              uuid={uuid}
              relation={props.relation}
              setSelected={(data) => setSelected(data)}
              data={selected}
              onSubmit={() => {
                load();
              }}
              onClickAttach={() => toggleAttachModal(true)}
              cancel={() => toggleView(!isGridView)}
              reloadMediaRelation={reloadMedia}
            />
          )}
        </Grid>
        <CsvExcelModalImport
          data={importSkuData?.data}
          fileModel="/api/storage/_modelos_importacao/Layout_Importação_SKUs_UMAMI.zip"
          onSubmit={(files) => onImportSkus(files[0])}
          loading={importSkuData?.loading}
          title="Importar SKUs"
          open={productImportModal}
          onClose={() => {
            setSkuImportModal(false);
            setImportSkuData(initialSkuImportData);
          }}
          onCancel={() => {
            setSkuImportModal(false);
            setImportSkuData(initialSkuImportData);
          }}
        />
        {/* <CustomExportModal
          storeId={productCtxt?.loja?.id}
          open={customModalOpen}
          handleCloseModal={() => setCustomModalOpen(false)}
          isExportingToFile={false}
          exportToFile={() => doExport()}
        /> */}
        {/* <CreateSkuModal
          open={isModalOpen}
          onClose={() => toggleModal(false)}
          onSubmit={() => load()}
        /> */}
        {selected && (
          <SkuCreateAttachment
            data={selected}
            open={openAttach}
            onClose={() => toggleAttachModal(false)}
            onUpload={handleUpload}
            format="media"
            maxSize={20000000}
          />
        )}
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplaySku;

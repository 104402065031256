import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  IntegratorFields,
  IDataIntegrator,
  IDataIntegratorErrors,
  IHookFormIntegrator,
  IntegratorRelations,
  IIntegratorPagination,
  IIntegratorFilter,
  IIntegratorHubsellFilter,
} from 'app/types/data/IDataIntegrator';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';
import { IFromIntegratorPagination } from 'app/types/data/IDataFromIntegrator';

export default class Integrator_Api extends Model_Api {
  static self = new Integrator_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataIntegratorErrors {
    const resp: IDataIntegratorErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormIntegrator[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as IntegratorFields;
      const item: IHookFormIntegrator = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IIntegratorFilter,
    relation?: IDataRelation<IntegratorRelations>
  ): Promise<IIntegratorPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/integrador`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IIntegratorPagination;
      else
        return (await Api.get(Endpoints.Integrator.main, { params })).data as IIntegratorPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Integrators', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataIntegrator) {
    try {
      return (await Api.get(`${Endpoints.Integrator.main}/${data.id}`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Integrators', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataIntegrator,
    relation?: IDataRelation<IntegratorRelations>
  ): Promise<IDataIntegrator> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/integrador`, data)
        ).data.integrador as IDataIntegrator;
      } else {
        resp = (await Api.post(Endpoints.Integrator.main, data)).data.integrador as IDataIntegrator;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Integrators', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataIntegrator,
    relation?: IDataRelation<IntegratorRelations>
  ): Promise<IDataIntegrator> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/integrador`, data)
        ).data.integrador as IDataIntegrator;
      } else {
        resp = (await Api.put(`${Endpoints.Integrator.main}/${data.id}`, data)).data
          .integrador as IDataIntegrator;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Integrators', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataIntegrator,
    relation?: IDataRelation<IntegratorRelations>
  ): Promise<IDataIntegrator> {
    try {
      var resp: IDataIntegrator;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/integrador/${data.id}`
          )
        ).data.integrador;
      else resp = (await Api.delete(`${Endpoints.Integrator.main}/${data.id}`)).data.integrador;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Integrators', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataIntegrator
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.Integrator.main}/${model.id}/attachments`
            : `/media${Endpoints.Integrator.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(integrador: IDataIntegrator, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.Integrator.main}/${integrador.id}/attach`, formData))
        .data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Integrators', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Integrator.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Integrators', handleErros);
      throw handleErros;
    }
  }

  static async listIntegratorProducts(
    integrator: IDataIntegrator,
    params?: IIntegratorFilter
  ): Promise<IFromIntegratorPagination> {
    try {
      return (
        await Api.get(`${Endpoints.Integrator.main}/${integrator.id}/receber/produtos`, { params })
      ).data as IFromIntegratorPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Integrators', handleErros);
      throw handleErros;
    }
  }

  static async receiveIntegratorProducts(
    integrator: IDataIntegrator,
    productsFilter: number[] | string[] | string,
    params?: IIntegratorHubsellFilter
  ): Promise<any> {
    try {
      return (
        await Api.put(`${Endpoints.Integrator.main}/${integrator.id}/receber/produtos`, {
          produtos: productsFilter,
          ...params,
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Integrators', handleErros);
      throw handleErros;
    }
  }
}

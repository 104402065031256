import { Badge, Button, Icon, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import SearchInput from '../Inputs/SearchInput';

const GridFormView = ({ options, onChangeView }) => {
  const [isGrid, setIsGrid] = React.useState(true);
  const [optionView, setOptionView] = React.useState('grid');
  const [title, setTitle] = React.useState('Visualização em tabela');

  React.useEffect(() => {
    //if (options) setIsGrid(options?.state === 'grid');
    setOptionView(options?.state || 'grid');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    switch (optionView) {
      case 'grid':
        setTitle('Visualização em tabela');
        break;
      case 'form':
        setTitle('Visualização em formulário');
        break;
      case 'card':
        setTitle('Visualização em cartões');
        break;
      default:
        setTitle('Visualização em tabela');
        break;
    }
  }, [optionView]);

  function handleOnClick() {
    setIsGrid(!isGrid);
    if (onChangeView) onChangeView();
  }

  return (
    <Tooltip title={title} onClick={handleOnClick}>
      <Button
        color="inherit"
        disabled={options?.disabled || false}
        sx={{ mr: 3, p: 0, minWidth: 'initial' }}
      >
        {optionView === 'grid' && <Icon>grid_on</Icon>}
        {optionView === 'form' && <Icon>wysiwyg</Icon>}
        {optionView === 'card' && <Icon>grid_view</Icon>}
      </Button>
    </Tooltip>
  );
};

const toolbarIconList = ({
  data,
  rowSelection,
  options,
  searchValue,
  onSearchChange,
  onAddClick,
  onAddRowClick,
  onMinusClick,
  onClickView,
  onClickRefresh,
  onDeleteClick,
  onProductCurate,
  onSkuCurate,
  onClickAttach,
  onImportClick,
  apiRef,
  hasExportButton,
  fileName,
  onResetForm,
  onSaveRow,
  onFileImport,
  onBackClick,
  exportGridData,
  navigate,
  navigateToPage,
  selectAllPageRows,
  duplicateRow,
  onClickDownloadMedia,
  extraButtons = [],
}) => {
  const { data: gridData, selectedRow, page, total, isLoadingGrid } = options;
  const index = gridData?.findIndex(
    (obj) => obj?.id === selectedRow?.id && obj?.name === selectedRow?.name
  );

  return [
    /* {
    icon: 'home',
    title: 'Home',
    onClick: onClickHome ? () => onClickHome() : undefined,
  }, */
    /* {
    icon: 'filter_alt',
    children: [{ icon: 'arrow_drop_down' }],
    title: 'Filtros dinâmicos',
  }, */
    {
      type: 'filter',
      visible: Boolean(options.filter && options.filter.columns),
      icon: 'filter_alt',
      children: [{ icon: 'arrow_drop_down' }],
      title: 'Filtros dinâmicos',
    },
    {
      icon: 'arrow_back_ios',
      title: 'Voltar',
      visible: Boolean(onBackClick),
      onClick: onBackClick ? () => onBackClick() : undefined,
    },
    {
      type: 'icon',
      disabled: !Boolean(options?.total?.value),
      visible:
        (selectAllPageRows && Boolean(options?.total?.value) && options?.view?.state === 'grid') ||
        options?.view?.showSelectCheckbox,
      icon:
        options?.data?.length === options?.multipleSelected?.length && options?.isCheckboxOn
          ? 'check_box'
          : options?.isCheckboxOn &&
            options?.multipleSelected?.length >= 0 &&
            options?.multipleSelected?.length < options?.total?.value
          ? 'indeterminate_check_box'
          : 'check_box_outline_blank_icon',
      title: 'Selecionar',
      onClick: () =>
        selectAllPageRows && selectAllPageRows(options?.massCurator?.isAllPageRowsSelected),
    },
    {
      type: 'icon',
      visible: Boolean(onClickView),
      component: <GridFormView options={options?.view} onChangeView={onClickView} />,
    },
    {
      type: 'download_media',
      visible: Boolean(onClickDownloadMedia),
      disabled:
        !gridData?.length || isLoadingGrid || selectedRow?.custom_properties?.as_copy !== false,
      icon: 'image_icon',
      children: [{ icon: 'file_downloaded_rounded_icon' }],
      title: 'Baixar',
      onClick: onClickDownloadMedia ? () => onClickDownloadMedia() : undefined,
    },
    {
      icon: 'add_box',
      title: 'Incluir',
      visible: Boolean(onAddClick),
      onClick: onAddClick ? () => onAddClick() : undefined,
    },
    {
      icon: 'playlist_add',
      title: 'Adicionar Linha',
      visible: Boolean(onAddRowClick),
      onClick: onAddRowClick ? () => onAddRowClick() : undefined,
    },
    {
      icon: 'indeterminate_check_box',
      title: 'Retirar',
      visible: Boolean(onMinusClick),
      onClick: onMinusClick ? () => onMinusClick() : undefined,
    },
    {
      icon: 'delete',
      title: 'Excluir',
      disabled: options?.canDeleteAttribute,
      visible: Boolean(onDeleteClick),
      onClick: onDeleteClick ? () => onDeleteClick() : undefined,
    },
    {
      icon: 'content_copy',
      title: 'Copiar',
    },
    {
      icon: 'cached',
      title: 'Atualizar',
      visible: Boolean(onClickRefresh),
      onClick: onClickRefresh ? () => onClickRefresh() : undefined,
    },
    {
      icon: 'check_box',
      title: 'Salvar',
    },
    {
      icon: 'disabled_by_default',
      visible: Boolean(options?.cancel?.onClick) && options?.view?.state === 'grid',
      title: 'Cancelar',
      onClick: options?.cancel?.onClick ? () => options?.cancel?.onClick() : undefined,
    },
    {
      icon: 'attachment',
      title: 'Anexar',
      visible: Boolean(onClickAttach),
      onClick: onClickAttach ? () => onClickAttach() : undefined,
    },
    {
      icon: 'system_update_alt',
      title: 'Importar da umami',
      visible: Boolean(onImportClick),
      onClick: onImportClick ? () => onImportClick() : undefined,
    },
    {
      type: 'icon',
      icon: 'keyboard_double_arrow_left_icon',
      title: 'Primeiro',
      visible: navigateToPage || navigate,
      disabled: !gridData?.length || isLoadingGrid || (page === 1 && index <= 0),
      onClick: () => {
        if (page + 1 !== total?.total_pages && page + 1 !== 1) {
          navigateToPage(1, 'first');
        } else {
          navigate(gridData[0]);
        }
      },
    },
    {
      type: 'icon',
      icon: 'keyboard_arrow_left_icon',
      title: 'Anterior',
      visible: navigateToPage || navigate,
      disabled: !gridData?.length || isLoadingGrid || (page === 1 && index <= 0),
      onClick: () => {
        if (!selectedRow) {
          navigate(gridData[0]);
          return;
        }

        if (gridData[index - 1]) {
          navigate(gridData[index - 1]);
        } else if (page > 1) {
          navigateToPage(page - 1, 'last');
        }
      },
    },
    {
      type: 'icon',
      icon: 'keyboard_arrow_right_icon',
      title: 'Próximo',
      disabled:
        !gridData?.length ||
        isLoadingGrid ||
        (page === total?.total_pages && index === gridData?.length - 1),
      visible: navigateToPage || navigate,
      onClick: () => {
        if (!selectedRow) {
          navigate(gridData[0]);
          return;
        }
        const index = gridData.findIndex(
          (obj) => obj.id === selectedRow.id && obj.name === selectedRow.name
        );
        if (gridData[index + 1]) {
          navigate(gridData[index + 1]);
        } else if (page < total?.total_pages) {
          navigateToPage(page + 1);
        }
      },
    },
    {
      type: 'icon',
      icon: 'keyboard_double_arrow_right_icon',
      title: 'Último',
      visible: navigateToPage || navigate,
      disabled:
        !gridData?.length ||
        isLoadingGrid ||
        (page === total?.total_pages && index === gridData?.length - 1),
      onClick: () => {
        if (total?.total_pages > 1 && page !== total?.total_pages) {
          navigateToPage(total?.total_pages, 'last');
        } else {
          navigate(gridData[gridData?.length - 1]);
        }
      },
    },

    {
      icon:
        options?.requestLoading?.duplicateCategorie |
        options?.requestLoading?.duplicateSegmentation ? (
          <CircularProgress size={24} />
        ) : (
          'content_copy_icon'
        ),
      title: 'Duplicar',
      visible: duplicateRow && Boolean(options?.total?.value),
      disabled:
        !Boolean(options?.total?.value) ||
        !selectedRow ||
        options?.requestLoading?.duplicateCategorie ||
        options?.requestLoading?.duplicateSegmentation,
      onClick: () => {
        duplicateRow();
      },
    },
    {
      icon: 'save_icon',
      title: 'Salvar',
      form: 'formik-orientation',
      visible:
        Boolean(onSaveRow) &&
        Boolean(options?.total?.value) &&
        Boolean(options?.view?.state === 'grid'),
      onClick: () => onSaveRow && onSaveRow(),
    },
    {
      icon: 'undo_icon',
      title: 'Desfazer',
      visible: Boolean(onResetForm) && options?.view?.state !== 'grid',
      onClick: () => onResetForm && onResetForm(),
    },
    {
      icon: 'download_icon',
      title: 'Importar',
      visible: Boolean(onFileImport),
      onClick: () => onFileImport && onFileImport(),
    },
    {
      type: 'icon',
      icon: options?.isLoadingAllGridRows ? <CircularProgress size={24} /> : 'upload_icon',
      visible:
        hasExportButton && (Boolean(options?.total?.value) || options?.displayName === 'product'),
      title: 'Exportar',
      onClick: () => exportGridData && exportGridData(),
    },
    {
      type: 'mass_curator',
      disabled: !options?.isCheckboxOn || options?.multipleSelected?.length === 0,
      visible: options?.view?.mass_curator && Boolean(options?.total?.value),
      icon: 'person_2_icon',
      title: 'Curador em massa',
    },
    {
      type: 'filter_by_agnostic', // filters projects that has no origin_id
      disabled: options?.filter_by_agnostic?.isFilteringByAgnosticDisabled,
      visible: options?.view?.filter_by_agnostic && Boolean(options?.total?.value),
      icon: 'filter_list_icon',
      title: 'Filtrar por dados que não pertencem à base agnóstica.',
    },
    {
      type: 'copy_to_base',
      disabled: !options?.multipleSelected?.length || !options?.isCheckboxOn,
      visible: options?.view?.copy_to_base && Boolean(options?.total?.value),
      icon: 'dataset_linked_icon',
      title: 'Enviar para base agnóstica',
      loading: options?.copy_to_base?.isCopyingToBase,
      relationsToCopyOptions: options?.copy_to_base?.relationsToCopyOptions,
      onClick: (relations) =>
        options?.copy_to_base?.copyToBaseCallback &&
        options?.copy_to_base?.copyToBaseCallback(relations),
    },
    {
      type: 'icon',
      disabled: !options?.isCheckboxOn || options?.multipleSelected?.length === 0,
      visible: options?.view?.curate_process && Boolean(options?.total?.value),
      icon: 'done_all_icon',
      title: 'Curar',
      onClick: onProductCurate ? () => onProductCurate() : undefined,
    },
    {
      type: 'icon',
      disabled: !options?.isCheckboxOn || options?.multipleSelected?.length === 0,
      visible: options?.view?.sku_curate && Boolean(options?.total?.value),
      icon: 'done_all_icon',
      title: 'Curar',
      onClick: onSkuCurate ? () => onSkuCurate() : undefined,
    },
    ...extraButtons,
    {
      visible: Boolean(options?.total?.value),
      component: (
        <Tooltip title="Total">
          <Badge
            sx={{ mr: 2 }}
            color="secondary"
            badgeContent={options?.total?.value || 0}
            max={999}
          >
            <Icon>comment</Icon>
          </Badge>
        </Tooltip>
      ),
    },
    {
      type: 'input',
      visible: Boolean(onSearchChange),
      component: (
        <SearchInput value={searchValue} onDebounceValueChange={(value) => onSearchChange(value)} />
      ),
    },
  ];
};
export default toolbarIconList;

import { Grid, Paper, TablePagination } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import VirtualShowcase_Api from 'app/api/VirtualShowcase_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataVirtualShowcase,
  IDataVirtualShowcaseErrors,
  VirtualShowcaseRelations,
} from 'app/types/data/IDataVirtualShowcase';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import React, { Fragment } from 'react';
import { CSVLink } from 'react-csv';
import ViewShowcaseProduct from './ViewShowcaseProduct';
import VirtualShowcase from './VirtualShowcase';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
  },
  {
    field: 'nm_produto',
    headerName: 'Nome',
    editable: true,
  },
  {
    field: 'marca',
    headerName: 'Marca',
    editable: true,
    //renderEditCell: (params: GridRenderEditCellParams) => <SelectBrand variant='standard' onChange={(marca) => customComponentFilterOnChange(marca)} />,
  },
  {
    field: 'loja',
    headerName: 'Loja',
    editable: true,
  },
  {
    field: 'categoria',
    headerName: 'Categoria',
    editable: true,
  },
  {
    field: 'nr_estoque_minimo',
    headerName: 'Estoque Mínimo',
    type: 'number',
    editable: true,
  },
  {
    field: 'nr_estoque',
    headerName: 'Estoque',
    type: 'number',
    editable: true,
  },
  {
    field: 'vl_precopor',
    headerName: 'Preço',
    type: 'number',
    editable: true,
  },
  {
    field: 'status_umami',
    headerName: 'Status',
    editable: true,
    type: 'singleSelect',
    valueOptions: [
      { value: '1', label: 'Ativo' },
      { value: '2', label: 'Inativo' },
      { value: '3', label: 'Pausado' },
      { value: '4', label: 'Entregue' },
      { value: '5', label: 'Ativo com prioridade' },
    ],
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataVirtualShowcase>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataVirtualShowcase>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

const columnsStore = columns.filter((col) => col.field !== 'loja');

//const header = columns.map((item) => item.headerName);

const UPDATE_MSG = getMessage('Segmentação', 'update');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplayVirtualShowcase {
  relation?: IDataRelation<VirtualShowcaseRelations>;
}

const DisplayVirtualShowcase = (props: IDisplayVirtualShowcase) => {
  const [viewProduct, setViewProduct] = React.useState(false);
  const [data, setData] = React.useState<IDataVirtualShowcase[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataVirtualShowcase | undefined>(undefined);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal /* setAlertModal */] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [isLoadingAllGridRows /* setLoadingAllGridRows */] = React.useState<boolean>(false);
  const [allPageRows /* setAllPageRows */] = React.useState<any[][]>([]);
  const csvLink = React.useRef<any | null>();

  useTrailHistory({
    selected,
    selectedName: selected?.name,
    displayName: 'Vitrine',
    toggleView: () => {},
  });

  const colsFilter = props.relation?.relation === 'Store' ? columnsStore : columns;

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params]);

  async function load() {
    setLoading(true);
    try {
      const items = await VirtualShowcase_Api.list(
        {
          q: search,
          page: page + 1,
          per_page: pageSize,
          ...params,
        },
        props.relation
      );

      setData(items.data);
      setTotal(items.pagination.total);
      setTotalPages(items.pagination.total_pages);
    } catch (error: IDataVirtualShowcaseErrors | unknown) {
      if (error) {
        const err = error as IDataVirtualShowcaseErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  function onFilter(filters: IParamsQs) {
    setParams(filters);
  }

  function handleOnBackClick() {
    setSelected(undefined);
    setViewProduct(false);
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      data={data}
      rowSelection={selected}
      options={
        selected && viewProduct
          ? {}
          : {
              total: {
                value: total,
                total_pages: totalPages,
              },
              filter: {
                columns: colsFilter,
                onFilter: onFilter,
              },
              page: page + 1,
              isLoadingAllGridRows: isLoadingAllGridRows,
              isLoadingGrid: loading,
              data: data,
              selectedRow: selected,
            }
      }
      onClickRefresh={selected && viewProduct ? undefined : load}
      searchValue={search}
      onSearchChange={selected && viewProduct ? undefined : (value) => setSearch(value)}
      onBackClick={selected && viewProduct ? handleOnBackClick : undefined}
      sticky
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid
          container
          display="flex"
          flexDirection="column"
          spacing={3}
          style={{ position: 'relative' }}
        >
          <ToolbarComponent />
          <CSVLink
            data={allPageRows}
            target="_blank"
            ref={csvLink}
            filename="Segmentação"
            separator=";"
          />
          {!viewProduct && (
            <Grid container item component={Paper}>
              <Grid item xs={12}>
                <VirtualShowcase
                  //selected={selected}
                  data={data}
                  onSelected={(data) => {
                    setSelected(data);
                    setViewProduct(true);
                  }}
                  loadingData={loading}
                  //toggleView={(value) => toggleView(value)}
                  pagination={{ current_page: page, total, per_page: pageSize }}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                {Boolean(data.length) && !loading && !viewProduct && (
                  <TablePagination
                    style={{
                      position: 'absolute',
                      bottom: -7,
                      right: 1,
                    }}
                    component="div"
                    labelRowsPerPage="Linhas por página:"
                    count={total}
                    page={page}
                    onPageChange={(
                      event: React.MouseEvent<HTMLButtonElement> | null,
                      newPage: number
                    ) => setPage(newPage)}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value, 10))}
                    labelDisplayedRows={({ from, count, to }) =>
                      `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`
                    }
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        {selected && viewProduct && <ViewShowcaseProduct product={selected} />}
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayVirtualShowcase;

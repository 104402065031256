import { Avatar, Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import Comment_Api from 'app/api/Comment_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import { CommentRelations, IDataComment, IDataCommentErrors } from 'app/types/data/IDataComment';
import { IDataUser } from 'app/types/data/IDataUser';
import filterObjectColumns from 'app/utils/filterObjectColumns';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import { AvatarName } from 'app/views/management/user/components/DisplayUser';
import React, { Fragment, SetStateAction } from 'react';
import { CSVLink } from 'react-csv';
import CommentCreateAttachment from '../CommentCreateAttachment';
import CommentFormContainer from '../CommentFormContainer';

const hiddenColumns = {
  author_id: false,
  nm_host: false,
  ds_iphost: false,
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'author_id',
    headerName: 'Author ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'author',
    headerName: 'Autor',
    hideable: true,
    flex: 1,
    editable: false,
    renderCell: (params: GridRenderCellParams<IDataUser>) => (
      <AvatarName>
        <Avatar alt={params.value?.name} src={params.value?.avatar} sizes="80" />
        <span>{params.value?.name}</span>
      </AvatarName>
    ),
  },
  {
    field: 'body',
    headerName: 'Mensagem',
    flex: 1,
    editable: false,
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

const header = columns.map((item) => item.headerName);

const UPDATE_MSG = getMessage('Comentário', 'update');
const DELETE_MSG = getMessage('Comentário', 'delete');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplayComment {
  relation?: IDataRelation<CommentRelations>;
}

const DisplayComment = (props: IDisplayComment) => {
  const { handleResetForm } = useUtilsContext();
  const [isGridView, toggleView] = React.useState(true);
  const [data, setData] = React.useState<IDataComment[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataComment | undefined>(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [openAttach, toggleAttachModal] = React.useState<boolean>(false);
  const [rowStopReason, setRowStopReason] = React.useState<string>();
  const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [isLoadingAllGridRows, setLoadingAllGridRows] = React.useState<boolean>(false);
  const [allPageRows, setAllPageRows] = React.useState<any[][]>([]);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const csvLink = React.useRef<any | null>();

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    if (isGridView) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, isGridView]);

  async function load() {
    setLoading(true);
    try {
      const Comments = await Comment_Api.list(
        { q: search, page: page + 1, per_page: pageSize, order: 'desc', ...params },
        props.relation
      );
      setData(Comments.data);
      setTotal(Comments.pagination.total);
      setTotalPages(Comments.pagination.total_pages);
      setSelected(
        rowToSelect === 'last' ? Comments.data[Comments.data.length - 1] : Comments.data[0]
      );
    } catch (error: IDataCommentErrors | unknown) {
      if (error) {
        const err = error as IDataCommentErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  const processRowUpdate = async (newRow, oldRow) => {
    if (rowStopReason === 'enterKeyDown' || onSaveRow) {
      const response = await Comment_Api.update(newRow);
      load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
      setOnSaveRow(null);
      return response;
    }
    setOnSaveRow(null);
    return oldRow;
  };

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function handleDelete() {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (selected) await Comment_Api.delete(selected, props.relation);
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataCommentErrors | unknown) {
      const err = error as IDataCommentErrors;
      if (err.errors.status && err.errors.message)
        setAlertModal({
          open: true,
          title: `${err.errors.status} - Não foi possível prosseguir`,
          message: err.errors.message,
          onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
        });
    }
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: `${props.relation ? 'Retirar' : 'Remover'} comentário`,
      message: (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} a comentário{' '}
          <strong>
            {selected?.id} - {selected?.body}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  function handleUpload(Comment: IDataComment) {
    setSelected(Comment);
  }

  async function loadAllGridRows() {
    try {
      setLoadingAllGridRows(true);
      const items = await Comment_Api.list(
        { q: search, page: page + 1, per_page: total, ...params },
        props.relation
      );
      items.data.forEach((item) => {
        item['created_at'] = formatDateBR(item['created_at']);
        item['updated_at'] = formatDateBR(item['updated_at']);
      });
      const fields = columns.map((item) => item.field);
      const result: SetStateAction<any[][]> = [
        fields,
        ...items.data.map((obj) => fields.map((key) => obj[key])),
      ];
      const filteredResult = filterObjectColumns(result);
      filteredResult[0] = header;
      setAllPageRows(filteredResult);
      setLoadingAllGridRows(false);
      csvLink?.current?.link?.click();
    } catch (error: IDataCommentErrors | unknown) {
      setLoadingAllGridRows(false);
      if (error) {
        const err = error as IDataCommentErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    }
  }

  function onFilter(filters: IParamsQs) {
    setParams(filters);
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      options={{
        view: {
          state: isGridView ? 'grid' : 'form',
        },
        cancel: {
          onClick: () => setSelected(undefined),
        },
        total: {
          value: total,
          total_pages: totalPages,
        },
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        isLoadingAllGridRows: isLoadingAllGridRows,
        isLoadingGrid: loading,
        data: data,
        selectedRow: selected,
      }}
      onAddClick={() => {
        toggleView(false);
        setSelected(undefined);
      }}
      onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
      onClickView={() => toggleView(!isGridView)}
      onClickRefresh={() => load()}
      //onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
      onClickAttach={() => toggleAttachModal(true)}
      searchValue={search}
      onSearchChange={(value) => setSearch(value)}
      onResetForm={() => handleResetForm('CommentForm')}
      //onSaveRow={() => setOnSaveRow('save')}
      exportGridData={() => loadAllGridRows()}
      navigate={(value) => {
        setSelected(value);
      }}
      navigateToPage={(page, rowToSelect = 'first') => {
        setRowToSelect(rowToSelect);
        setPage(page - 1);
      }}
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          {!isGridView && <ToolbarComponent />}
          {isGridView && (
            <TableMUI
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns,
                },
              }}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  toggleView(false);
                }
              }}
              density="standard"
              columns={columns}
              rows={data}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                const row = data.filter((item) => item.id === newSelection[0]);
                setSelected(row[0]);
              }}
              selectionModel={[selected?.id as number]}
              customToolbar={() => <ToolbarComponent hasExportButton isGridRef />}
            />
          )}
          <CSVLink
            data={allPageRows}
            target="_blank"
            ref={csvLink}
            filename="comentários"
            separator=";"
          />
          {!isGridView && (
            <CommentFormContainer
              setSelected={(data) => setSelected(data)}
              relation={props.relation}
              data={selected}
              onSubmit={() => load()}
              cancel={() => toggleView(!isGridView)}
              onClickAttach={() => toggleAttachModal(true)}
            />
          )}
        </Grid>
        {selected && (
          <CommentCreateAttachment
            data={selected}
            open={openAttach}
            onClose={() => toggleAttachModal(false)}
            onUpload={handleUpload}
          />
        )}
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayComment;
